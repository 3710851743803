export function enumToArray(inputEnum) {
    let type = typeof Object.values(inputEnum)[0];
    if (type === "number") {
        let array = Object.keys(inputEnum).map(key => ({value: inputEnum[key], text: key}))
        return array.splice(array.length / 2, array.length);
    }
    if (type === "string") {
        return Object.values(inputEnum);
    }
    return inputEnum;
}

export function getPlural(len: number, singular: string, pluralTwoToFour: string, pluralFiveToNine: string ) {
    if (len == 0 || (len > 20 && len % 10 == 0)) return pluralTwoToFour;
    if (len == 1 || (len > 20 && len % 10 == 1)) return singular;
    if ((len > 1 && len < 5) || (len > 20 && len % 10 > 1 && len % 10 < 5))
        return pluralFiveToNine;
    if ((len > 4 && len < 21) || (len > 20 && len % 10 > 4 && len % 10 < 10)) return pluralTwoToFour;
    if (len > 20 && len % 10 > 1 && len % 10 < 5) return pluralFiveToNine;
    return singular;
}