import "@babel/polyfill";
// vue and it's plugins
import Vue from "vue";
import "./plugins/vuetify";
import "./plugins/styles.scss";
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { router } from "./router";
import store from "./store/store";
import Vuex from 'vuex';
import Main from '_C/Home/Main.vue';
import Message from "_C/shared/Message.vue";
import Confirm from "_C/shared/Confirm.vue";
import ReactiveState from '_C/shared/ReactiveState.vue';
// types
import "./types/Date"
// other
import { HttpQueryFactory } from '@/utils/httpUtils';

Vue.component('message', Message);
Vue.component('confirm', Confirm);
Vue.component('reactivestate', ReactiveState);

Vue.config.productionTip = false;

Vue.use(Vuex);

const httpQuery = HttpQueryFactory(
    () => window.localStorage.getItem('rhythmtoken'),
    (p) => {

        if (p && p.status) {
            if (p.status == 401) router.push("/noapp/auth");
        }
    }
);
new Vue({ router, store, render: h => h(Main) }).$mount("#app");

export { httpQuery }
