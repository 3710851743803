<template>
    <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel" style="z-index: 201" @input="cancel">
        <v-card style="border-radius: 6px">
            <v-toolbar v-if="!!options.title" :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ options.title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pt-4" v-html="options.text"></v-card-text>
            <v-card-actions class="pt-0 pb-4">
                <v-spacer/>
                <v-btn :color="options.color" @click.native="agree">Да</v-btn>
                <v-btn class="ml-3" :color="options.color" outline @click.native="cancel">Нет</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { ConfirmModel } from "@/model/shared/confirm";

@Component
export default class Confirm extends Vue {
    dialog = false;
    resolve = null;
    reject = null;
    options: ConfirmModel = {
        title: null,
        text: "Вы уверены?",
        color: 'primary',
        width: '300'
    };
    defaultOptions: ConfirmModel = {
        title: null,
        text: "Вы уверены?",
        color: 'primary',
        width: '300'
    };
    mounted() { Vue.prototype.$confirm = this; }

    open(options?) {
        this.dialog = true;
        this.options = Object.assign(this.options, this.defaultOptions);
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        })
    }

    agree() {
        this.resolve(true);
        this.dialog = false;
    }

    cancel() {
        this.resolve(false);
        this.dialog = false;
    }

    /** how to use:
     *  let res = await this.$confirm.open({
            title: "Удаление полиса",
            text: "Вы уверены в своём выборе? Это поимеет последствия, которые поимеют вас.",
            color: "error"
        })
        if (res) {
            console.log("YES");
        }
        else {
            console.log("NO");
        }
     */
}
</script>