<template>
    <div class="m-container">
        <v-slide-y-transition group leave-absolute>
            <v-alert
                v-for="item in messages"
                :key="item.id"
                :value="true"
                class="alert lighten-2"
                :color="item.styleModel.alertClass"
            >
                <div class="opacity-background">
                    <v-icon size="40">
                        {{getStyleModel(item.type).icon}}
                    </v-icon>
                    <span class="message" v-html="item.text"/>
                </div>
                <v-icon @click="hide(item.id)" size="24" class="close-btn" color="black">mdi-close</v-icon>
            </v-alert>
            <div style="min-width: 320px;height: 0px;" key="placeholder">
                &nbsp;
            </div>
        </v-slide-y-transition>
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { MessageItem, MessageType } from "@/model/shared/message";

@Component
export default class Message extends Vue {
    messages: MessageItem[] = [];

    mounted() {
        Vue.prototype.$message = this;
    }

    decreaser(id, time) { setTimeout(() => { this.hide(id) }, time) }

    hide(id) {
        var index = this.messages.map(x =>  x.id).indexOf(id);
        if (index > -1) this.messages.splice(index, 1);
    }

    msgCounter = 0;
    add(message: MessageItem = null) {
        let newMessage = null;
        if (message != null)
        newMessage = {
            id: this.msgCounter,
            type: message.type,
            styleModel: this.getStyleModel(message.type),
            text: message.text,
            leftTime: null
        } as MessageItem;
        this.decreaser(this.msgCounter, message.leftTime ? message.leftTime : 5000);
        this.messages.push(newMessage);
        this.msgCounter++;
    }

    getStyleModel(type: MessageType) {
        switch (type) {
            case MessageType.Error: return {
                icon: "mdi-block-helper",
                alertClass: "error"
            };
            case MessageType.Success: return {
                icon: "mdi-checkbox-marked-circle",
                alertClass: "success"
            };
            case MessageType.Info: return {
                icon: "mdi-information",
                alertClass: "info"
            };
            case MessageType.Warning: return {
                icon: "mdi-alert",
                alertClass: "warning"
            };
            case MessageType.Help: return {
                icon: "mdi-help-circle",
                alertClass: "help"
            };
            default: return {
                icon: "mdi-help-circle",
                alertClass: "info"
            };
        }
    }

    /** how to use:
     *  this.$message.add({
            text: "Данные обновлены",
            type: 1,
        })
     */

}
</script>

<style lang="scss">
.m-container {
    font-family: "Roboto";
    z-index: 101;
    position: absolute;
    display: flex;
    left: 50%;
    transform: translateX(-50%) translateY(20px);
    justify-content: center;
    align-items: center;
    .v-alert.alert {
        width: max-content;
        background: white;
        //margin-top: 20px;
        position: relative;
        display: flex;
        z-index: 102;
        min-width: 320px;
        max-width: 50vw;
        padding: 8px 20px;
        border-radius: 4px;
        min-height: 85px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        color: black;
        border: 1px solid #000 !important;
        .opacity-background {
            display: flex;
            align-items: center;
            .v-badge__badge {
                bottom: 2px;
                right: -7px;
            }
            .message {
                margin-left: 20px;
                font-size: 14px;
                white-space: pre-line;
            }
        }
    }
}
.close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>