import { parseISO, format } from 'date-fns';

Date.prototype.toJSON = function () {
    let originalHours = this.getHours();
    let hoursDiff = this.getHours() - this.getTimezoneOffset() / 60;
    this.setHours(hoursDiff);
    let timezoneString = this.toISOString().slice(0, -1);
    // set original hours so .toJSON does not change original value
    this.setHours(originalHours);

    return timezoneString;
};

Date.prototype.format = function (formatTokens: string): string {
    return format(this, formatTokens)
}

// in case if ISO strings came from server
String.prototype.format = function (formatTokens: string): string {
    return format(parseISO(this as string), formatTokens);
}