<template>
    <v-layout column align-center justify-center>
        <span class="white--text title mb-3" style="font-weight: 300">Регистрация</span>
        <v-card class="pa-3" width="320">
            <v-text-field label="E-mail" v-model="patient.login" />
            <v-text-field label="Пароль" v-model="patient.password" />
            <v-text-field label="ФИО пациента" v-model="patient.fullName" />
            <date-field label="Дата рождения" v-model="patient.birthDate" />
            <v-btn-toggle
                class="w-100 d-flex"
                dark mandatory
                v-model="patient.sex"
                style="margin: 6px 0px;"
            >
                <v-btn color="blue lighten-2" :value="Gender.Male">М</v-btn>
                <v-btn color="pink lighten-2" :value="Gender.Female">Ж</v-btn>
            </v-btn-toggle>
            <v-checkbox v-model="UACheck" class="mt-0 pt-0" hide-details>
                <template slot="label">
                    <span style="font-size: 14px;">Согласие на обработку персональных данных</span>
                </template>
            </v-checkbox>
            <v-btn
                color="success"
                block
                @click="register"
                :disabled="!UACheck || loading"
                @keyup.enter="register"
            >Зарегистрироваться</v-btn>
            <div>
                Нажимая на кнопку <b>"Зарегистрироваться"</b>, я соглашаюсь с <a href="https://google.ru/?q=Договор+оферты">договором оферты</a>
            </div>
        </v-card>
    </v-layout>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import { UserType, Gender } from "@/model/common";
import { enumToArray } from "@/plugins/utils";
import DateField from "_C/shared/DateField.vue";
import { Patient } from "@/model/patient";

@Component({ components: { DateField } })
export default class Register extends Vue {
    enumToArray = enumToArray;
    UserType = UserType;
    Gender = Gender;
    patient: any = new Patient();
    loading = false;
    UACheck = false;

    created() {
        this.patient.userType = UserType.Patient;
    }

    async register() {
        if (!this.UACheck) return;
        this.loading = true;
        try {
            let isRegistered = await this.$store.dispatch(
                "auth/register",
                this.patient
            );
            let isLoggedIn = false;
            if (isRegistered) {
                this.$message.add({
                    text: "Поздравляем, вы успешно зарегистрировались.",
                    type: 2
                });
                isLoggedIn = await this.$store.dispatch("auth/authorize", {
                    login: this.patient.login,
                    pass: this.patient.password
                });
            }
            if (isLoggedIn) this.$router.push("/app/dash");
        } catch {
                this.$message.add({
                    text: "Произошла ошибка. Попробуйте повторить позже.",
                    type: 1
                });
            this.loading = false;
        }
    }
}
</script>