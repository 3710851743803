<template>
    <v-navigation-drawer clipped fixed v-model="globalSidebarState" app :width="185">
        <v-list dense>
            <template v-for="(route, i) in routes">
                <v-list-tile v-if="route.meta.isVisible" :key="i" ripple :to="'/app/'+route.path">
                    <v-list-tile-content>
                        <v-list-tile-title style="text-align:center;" v-text="route.title" />
                    </v-list-tile-content>
                </v-list-tile>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import routes from "../../routes";
import { UserType } from "@/model/common";

@Component
export default class Siderbar extends Vue {
    state = true;

    get globalSidebarState() {
        return this.$store.state.sidebar;
    }
    set globalSidebarState(val) {
        this.$store.state.sidebar = val;
    }

    get currUserType(): UserType {
        let currUser = this.$store.state.auth.currentUser;
        if (!currUser) return null;
        return currUser.userType;
    }

    /** processes routes.ts object for currentUser for use in sidebar navs */
    get routes() {
        if (typeof this.currUserType !== "number") return [];
        let rootRoutes = routes.find(x => x.meta.rootPath == true).children;
        let navRoutes = (rootRoutes as any).filter(x =>
            this.filterByUserType(x)
        );
        return navRoutes;
    }

    filterByUserType = route =>
        route.meta.isVisible &&
        route.meta.allowedUserTypes.includes(this.currUserType);
}
</script>