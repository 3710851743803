<template>
<v-card-text v-if="isStarted">
    <v-layout column align-center justify-space-between>
        <div>
            <DateField v-model="startDateInput" :max="new Date().toISOString()" label="Укажите дату начала исследования"/>
        </div>
        <v-btn large class="my-3" color="success" depressed :loading="isStartDateSaving" :disabled="isStartDateSaving"
            @click="saveStartDate"
            v-text="'Сохранить'" />
    </v-layout>
</v-card-text>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Rhythm, RhythmStatus } from '_M/rhythmItems';
import DateField from '_C/shared/DateField.vue';

@Component({components: {DateField}})
export default class IsNewBlock extends Vue {
    get lastRhythm(): Rhythm { return this.$store.state.patient.lastRhythm; }
    get isStarted() {
        if (!this.lastRhythm) return true;
        return !this.lastRhythm.beginDate;
    }
    beginDate = null;
    startDateInput = null;
    isStartDateSaving = false;

    async saveStartDate() {
        console.log(this.beginDate, this.startDateInput)
        this.isStartDateSaving = true;
        await this.$store.dispatch("patient/setRhythmBeginDate", { id: this.lastRhythm.id, beginDate: this.startDateInput });
        await this.$store.dispatch("patient/getLastRhythm");
    }
}
</script>