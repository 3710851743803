import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

let router = new Router({
    routes,
});

router.beforeEach(async (to, from, next) => {
    let isAnon = !!to.meta.allowAnonymous;
    let isAuth = !!window.localStorage.getItem('rhythmtoken');
    if (isAnon || isAuth) {
        next();
    }
    else next("/noapp/auth");
});

export { router }