<template>
    <div style="position:relative">
        <v-menu
            :close-on-content-click="false"
            v-model="menu"
            lazy
            transition="slide-y-transition"
            full-width
            min-width="290px"
            bottom
            offset-y
            allow-overflow
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    :rules="!!rules ? [...rules, dateRule,minDateRule,maxDateRule] : [dateRule,minDateRule,maxDateRule]"
                    mask="##.##.####"
                    return-masked-value
                    validate-on-change
                    append-icon="ppd-calendar"
                    @click:prepend="$emit('input', new Date())"
                    @click:append="menu = !menu"
                    :label="label"
                    :placeholder="placeholder"
                    v-model="fieldDate"
                    :readonly="readonly"
                    :disabled="disabled"
                    :hide-details="hideDetails"
                    :error-messages="errorMessages"
                    v-on="on"
                />
            </template>
            <v-date-picker
                no-title
                ref="dpicker"
                @change="menu = false"
                v-model="picDate"
                :max="max"
                :min="min"
                :first-day-of-week="1"
                locale="ru"
                :readonly="readonly"
            />
        </v-menu>
    </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { stringIsNullOrEmpty } from "_U/strings";

@Component
export default class DateField extends Vue {
    @Prop() rules: Function[];
    @Prop() value: Date;
    @Prop({ default: "" }) label: string;
    @Prop({ default: "__.__.____" }) placeholder: string;
    @Prop() max: string;
    @Prop() min: string;
    @Prop({ default: false }) readonly: boolean;
    @Prop({ default: false }) disabled: boolean;
    @Prop({ default: false }) hideDetails: boolean;
    @Prop({ default: 120 }) nudge: number;
    @Prop() errorMessages: string[];

    menu: boolean = false;

    dateRule: Function = v =>
        stringIsNullOrEmpty(v) ||
        v.match(/[0-3][0-9][.][0-1][0-9][.](19|20)([0-9]{2})/) !== null ||
        "Неверный формат даты";

    created() {}
    get fieldDate(): string {
        return !!this.value && !!this.value.toLocaleDateString
            ? this.value.toLocaleDateString("ru-RU")
            : "";
    }
    set fieldDate(v: string) {
        if (this.dateRule(v) !== true) {
            return;
        }
        let dp: string[] = v.split(".");
        this.$emit("input", new Date(`${dp[2]}-${dp[1]}-${dp[0]}`));
    }

    get picDate(): string {
        return !!this.value && !isNaN(this.value.getTime())
            ? this.value.format("y-MM-dd")
            : "";
    }
    set picDate(v: string) {
        this.$emit("input", new Date(v));
    }

    minDateRule(v: string) {
        if (!this.min) return true;

        let dp: string[] = v.split(".");
        let date = new Date(`${dp[2]}-${dp[1]}-${dp[0]}`);

        if (!!date.getTime() || date.getTime() === 0) {
            if (date.getTime() < new Date(this.min).setHours(0))
                return "Слишком рано";
            else return true;
        } else return "Неверная дата";
    }

    maxDateRule(v: string) {
        if (!this.max) return true;

        let dp: string[] = v.split(".");
        let date = new Date(`${dp[2]}-${dp[1]}-${dp[0]}`);

        if (!!date.getTime() || date.getTime() === 0) {
            if (date.getTime() > new Date(this.max).setHours(23))
                return "Слишком поздно";
            else return true;
        } else return "Неверная дата";
    }
}
</script>