<template>
    <v-card>
        <v-card-text>
            <v-layout align-center>
                <v-select
                    v-model="timeSelection"
                    :items="availableTimePresets"
                    :disabled="availableTimePresets.length == 1"
                    hide-details
                />
                <v-slide-x-reverse-transition>
                    <v-btn
                        v-if="timeSelection == 'Указать'"
                        @click="switchDay()"
                        class="mr-0 ml-4"
                        v-text="day"
                        :disabled="isStartedToday"
                        :outline="isStartedToday"
                    />
                </v-slide-x-reverse-transition>
            </v-layout>
        </v-card-text>
        <v-layout
            v-if="timeSelection == 'Указать'"
            class="pb-3"
            align-center
            justify-center
        >
            <VueTimepicker
                class="timepicker"
                style="margin: 0 16px;"
                format="HH:mm"
                v-model="time"
                placeholder="Укажите время исследования"
                hide-clear-button
                hide-disabled-hours
                :hour-range="activeHours"
            />
        </v-layout>
    </v-card>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { TimePreset } from "_M/patient";
import { enumToArray } from "@/plugins/utils";
import { Rhythm } from "_M/rhythmItems";
import { addDays, isToday, isYesterday } from "date-fns";
import VueTimepicker from "vue2-timepicker";
import DateField from "_C/shared/DateField.vue";

@Component({ components: { VueTimepicker, DateField } })
export default class DayTimeInputs extends Vue {
    get lastRhythm(): Rhythm {
        return this.$store.state.patient.lastRhythm;
    }

    get availableTimePresets() {
        let presets = enumToArray(TimePreset);
        if (this.isAnalysisOverdue) {
            return presets.filter(x => x == TimePreset.Manual);
        }
        return presets;
    }

    /** time preset (string enum), which indicates a moment *it* happened */
    get timeSelection() {
        return this.$store.state.patient.timeSelection;
    }
    set timeSelection(val) {
        this.$store.state.patient.timeSelection = val;
    }
    @Watch("timeSelection")
    onTimeSelect() {
        this.switchDay();
    }

    switchDay() {
        if (this.day == this.Days.StartDay) this.day = this.Days.EndDay;
        else this.day = this.Days.StartDay;
        this.$emit("setRhythmDate");
    }

    get isStartedToday() {
        return isToday(this.lastRhythm.beginDate);
    }
    get isEndingToday() {
        return isYesterday(this.lastRhythm.beginDate);
    }

    get day(): string {
        return this.$store.state.patient.day;
    }
    set day(val) {
        this.$store.state.patient.day = val;
    }

    get time() {
        return this.$store.state.patient.time;
    }
    set time(val) {
        this.$store.state.patient.time = val;
    }

    get Days() {
        if (!this.lastRhythm) return { StartDay: "", EndDay: "" };
        let beginDate: Date = this.lastRhythm.beginDate;
        return {
            StartDay: beginDate.format("dd.MM.y"),
            EndDay: addDays(beginDate, 1).format("dd.MM.y")
        };
    }

    async mounted() {
        await this.$store.dispatch("patient/getLastRhythm");
        if (this.isAnalysisOverdue) {
            this.$emit("openPanel");
            this.timeSelection = TimePreset.Manual; // triggers switchDay()
        }
    }

    get isAnalysisOverdue() {
        if (!this.lastRhythm) return false;
        let startDay = this.lastRhythm.beginDate;
        return !isToday(startDay) && !isYesterday(startDay);
    }

    get activeHours() {
        if (this.isAnalysisOverdue) return Array.from(Array(24), (e, i) => i);
        else {
            if (
                (this.isStartedToday && this.day == this.Days.StartDay) ||
                (this.isEndingToday && this.day == this.Days.EndDay)
            )
                // return today hours from 00 to current hour
                return Array.from(Array(new Date().getHours()+1), (e, i) => i);
            // return 24 hours
            return Array.from(Array(24), (e, i) => i);
        }
    }
}
</script>
<style lang="scss">
.timepicker {
    position: relative;
    &:after {
        bottom: -1px;
        content: "";
        left: 0;
        position: absolute;
        -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        width: 100%;
        border-style: solid;
        border-width: thin 0;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }
    input:focus ~ :after {
        transform: scaleX(1);
    }
    &:before {
        bottom: -1px;
        content: "";
        left: 0;
        position: absolute;
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        width: 100%;
        border-color: rgba(0, 0, 0, 0.42);
        border-style: solid;
        border-width: thin 0 0;
    }
    .display-time:not(.any) {
        flex: 1 1 auto;
        line-height: 20px;
        padding: 8px 0;
        max-width: 100%;
        min-width: 0;
        width: 100%;
        border: unset;
        display: flex;
        min-height: inherit;
        position: relative;
        align-items: center;
        color: inherit;
        font-size: 16px;
        &:focus {
            outline: none;
        }
    }
    .dropdown {
        width: 15em;
    }
    .select-list:not(.z) {
        width: 15em;
    }
    ul.hours:not(.any) {
        .hint {
            visibility: hidden;
            position: relative;
            &:after {
                content: "Часы";
                display: flex;
                position: absolute;
                padding: 0.3em 0;
                font-size: 0.8em;
                top: 0;
                width: 100%;
                height: 100%;
                visibility: visible;
                justify-content: center;
                align-items: center;
            }
        }
    }
    ul.minutes:not(.any) {
        border-left: none;
        .hint {
            visibility: hidden;
            position: relative;
            &:after {
                content: "Минуты";
                display: flex;
                position: absolute;
                padding: 0.3em 0;
                font-size: 0.8em;
                top: 0;
                width: 100%;
                height: 100%;
                visibility: visible;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .active {
        background: #3f51b5 !important;
    }
}
</style>