import { TimePreset } from '@/model/patient';
import ApiHelper from '@/utils/ApiHelper';
import { httpQuery } from '@/main';
import { Rhythm, RhythmItem } from '@/model/rhythmItems';

class PatientState {
    lastRhythm: Rhythm = null;
    currentItem: RhythmItem = {
        volume: 0,
        isIntake: false,
        captureDate: null
    }
    timeSelection: TimePreset = TimePreset.Now;
    day: string = null;
    time = {HH: null, mm: null}
}

export const patientModule = {
    namespaced: true,
    state: new PatientState(),

    mutations: {
        //setBeginDate(state: PatientState, beginDate: Date) {
        //    state.lastRhythm.
        //}
    },

    actions: {
        /** Save item for rhythm for a patient */
        async addRhythmItem({state}, rhythmItem) {
            let url = new ApiHelper().addRhythmItem;
            let resp = await httpQuery("post", url, null, null, rhythmItem);
            if (!resp) return;
            return resp.isSuccess
        },
        /** Used for (+)-button control in app.vue for patient
         * and also for validation on Patient.vue */
        async getLastRhythm({ state }) {
            let url = new ApiHelper().getLastRhythm;
            let resp = await httpQuery("get", url);
            if (!resp) return;
            if (resp.isSuccess) {
                state.lastRhythm = resp.data;
            }
            else state.lastRhythm = null;
        },

        async setRhythmBeginDate({ state }, payload: { id: number, beginDate: Date }) {
            let url = new ApiHelper().getBeginDateUrl;
            let resp = await httpQuery("post", url,null,null, { beginDate: payload.beginDate, rythmId: payload.id });
            if (!resp) return;
            if (resp.isSuccess) {
                // state.lastRhythm.beginDate = payload.beginDate;
            }
        },

        async complete({ dispatch }) {
            let url = new ApiHelper().complete;
            let resp = await httpQuery("post", url);
            if (!resp) return;
            if (resp.isSuccess) {
                await dispatch("getLastRhythm");
            }
        },

        async removeMeasure({ dispatch }, id) {
            let url = new ApiHelper().removeMeasure;
            let resp = await httpQuery("delete", url, null, null, id);
            if (!resp) return;
            if (resp.isSuccess) {
                await dispatch("getLastRhythm");
            }
        },

        async updateMeasure({ state, dispatch }, item) {
            let url = new ApiHelper().updateMeasure;
            let resp = await httpQuery("put", url, null, null, { itemId: item.id, value: item.value, isIntake: item.isIntake });
            if (!resp) return;
            return resp.isSuccess
        }
    }
}