<template>
    <div id="app">
        <v-app id="inspire">
            <v-layout column align-center justify-center>
                <span
                    class="title mb-2"
                    style="font-weight: 300"
                    v-text="'Страница не найдена'"
                />
                <v-btn
                    to="/app/dash" class="pa-4"
                    style="text-align:center;"
                >
                    <v-icon class="mr-3">mdi-arrow-left</v-icon>
                    Вернуться на главную
                </v-btn>
            </v-layout>
        </v-app>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import DebugOverlay from "_C/shared/DebugOverlay.vue";
import { Prop, Watch, Component } from "vue-property-decorator";

@Component({ components: { DebugOverlay } })
export default class NotFound extends Vue {}
</script>