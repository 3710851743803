<template>
    <div class="w-100">
        <v-card v-if="!isEnded" class="mx-auto" max-width="900" width="380">
            <InputHeader :qtype="qtype" :isStarted="isStarted" />
            <IsNewBlock v-if="isNew" />
            <VolumeInput v-if="isStarted" :disabled="isSaving" @save="save()" />
            <v-expansion-panel v-if="isStarted" v-model="isPanelOpen">
                <v-expansion-panel-content
                    class="set-time-panel"
                    hide-actions
                    style="background: #f5f5f5"
                    :key="'panel'+rhythmItem.isIntake"
                >
                    <template v-slot:header>
                        <v-layout align-baseline justify-center>
                            <div class="mx-3" style="z-index: 1;" v-text="`Когда: ${displayTime}`" />
                            <v-icon
                                color="grey lighten-2"
                                class="time-down-icon"
                                v-text="'mdi-menu-down-outline'"
                            />
                        </v-layout>
                    </template>
                    <DayTimeInputs @setRhythmDate="setRhythmDate" @openPanel="isPanelOpen = 0" />
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-card>
        <h1 class="text-xs-center" v-else>Ваш анализ был завершён. Спасибо!</h1>
        <v-expand-transition>
            <v-card
                v-if="savedAnalyses.length > 0"
                class="mt-2 mx-auto subheading"
                flat
                max-width="900"
                width="400"
            >
                Сохранённые анализы за этот раз:
                <v-fade-transition group mode="out-in">
                    <div v-for="(anal, i) in savedAnalyses" :key="i">
                        <span v-text="anal" />
                    </div>
                </v-fade-transition>
            </v-card>
        </v-expand-transition>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import { RhythmItem, Rhythm, RhythmStatus } from "@/model/rhythmItems";
import { TimePreset } from "@/model/patient";
import InputHeader from "./InputHeader.vue";
import IsNewBlock from "./IsNewBlock.vue";
import VolumeInput from "./VolumeInput.vue";
import DayTimeInputs from "./DayTimeInputs.vue";
import {
    format,
    addDays,
    getDate,
    getHours,
    getMinutes,
    subMinutes,
    subHours,
    isToday,
    isYesterday
} from "date-fns";

@Component({
    components: { InputHeader, IsNewBlock, VolumeInput, DayTimeInputs }
})
export default class InputContainer extends Vue {
    @Prop({ default: null })
    qtype: string; /** URL Query object to pass to header component */

    created() {}

    get lastRhythm(): Rhythm {
        return this.$store.state.patient.lastRhythm;
    }
    get isNew() {
        return !this.lastRhythm || this.lastRhythm.status == RhythmStatus.New;
    }
    get isStarted() {
        return !this.lastRhythm || this.lastRhythm.status == RhythmStatus.InProgress;
    }
    get rhythmItem() {
        return this.$store.state.patient.currentItem;
    }

    savedAnalyses = [];
    isPanelOpen = null;
    isSaving = false;
    async save() {
        this.isSaving = true;
        this.setRhythmDate();
        let isSuccess = await this.$store.dispatch(
            "patient/addRhythmItem",
            this.rhythmItem
        );
        this.isSaving = false;
        if (isSuccess) {
            this.$message.add({
                type: this.$rs.msgType.Success,
                text: "Ваше измерение успешно внесено, спасибо"
            });
            this.savedAnalyses.push(
                `${this.rhythmItem.isIntake ? "Потреблено" : "Выделено"} ${
                    this.rhythmItem.volume
                } мл ${this.displayTime}`
            );
        }
    }

    get timeSelection(): string {
        return this.$store.state.patient.timeSelection;
    }
    get day(): string {
        return this.$store.state.patient.day;
    }

    get Days() {
        if (!this.lastRhythm) return { StartDay: "", EndDay: "" };
        let beginDate: Date = this.lastRhythm.beginDate;
        return {
            StartDay: format(beginDate, "dd.MM.y"),
            EndDay: format(addDays(beginDate, 1), "dd.MM.y")
        };
    }

    get time() {
        return this.$store.state.patient.time;
    }
    @Watch("time") onTimeChange() {
        this.setRhythmDate();
    }

    get displayTime() {
        if (this.timeSelection != TimePreset.Manual) return this.timeSelection;
        if (!this.time.HH || !this.time.HH) return `${this.day} в 00:00`;
        return `${this.day} в ${this.time.HH}:${this.time.mm}`;
    }

    setRhythmDate() {
        let itemDate = this.lastRhythm.beginDate;
        if (this.timeSelection == TimePreset.Manual) {
            if (this.day == this.Days.EndDay) itemDate = addDays(itemDate, 1);
            let timeParts = ["00", "00"];
            if (this.time.HH && this.time.mm)
                timeParts = [this.time.HH, this.time.mm];
            itemDate.setHours(
                Number.parseInt(timeParts[0]),
                Number.parseInt(timeParts[1])
            );
        } else {
            itemDate.setDate(getDate(new Date()));
            // time presets settings
            itemDate.setHours(getHours(new Date()), getMinutes(new Date()));
            if (this.timeSelection == TimePreset.FiveMinAgo)
                subMinutes(itemDate, 5);
            if (this.timeSelection == TimePreset.HourAgo) subHours(itemDate, 1);
        }
        this.rhythmItem.captureDate = itemDate;
    }

    get isEnded() {
        if (!this.lastRhythm) return false;
        else return this.lastRhythm.status == RhythmStatus.Complete;
    }
}
</script>
<style lang="scss">
.incdec-btn {
    min-width: unset;
    min-height: unset;
    height: 50px;
    width: 50px;
    border-radius: 4px;
}
.v-input--slider {
    align-items: center;
}
.v-input.volume-input {
    width: 115px;
    .v-input__slot {
        box-shadow: none !important;
        padding: 0 !important;
    }
    .v-text-field__suffix {
        margin-top: 28px;
        font-weight: 300;
    }
    input {
        font-weight: 300 !important;
        font-size: 56px !important;
        line-height: 1.35 !important;
        letter-spacing: -0.02em !important;
        max-height: 46px;
    }
}
.set-time-panel {
    .v-expansion-panel__header {
        padding: 0 !important;
        min-height: 30px;
        overflow: hidden;
    }
}
.time-down-icon {
    z-index: 0;
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    font-size: 130px;
    height: 8px;
    right: 0;
}
</style>