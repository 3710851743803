export enum MessageType {
    Error = 1,
    Success,
    Info,
    Warning,
    Help
}
export class MessageItem {
    id?: number = null;
    type?: MessageType = MessageType.Info;
    styleModel?: StyleModel = new StyleModel();
    text?: string;
    leftTime?: number;
}
export class StyleModel {
    icon: string = "";
    alertClass: string = "";
}