<template>
    <v-layout v-if="lastRhythm" column align-center justify-center>
        <span v-text="`Сведения об анализе.`" />
        <span v-text="`Номер: ${lastRhythm.id}`" />
        <span
            v-if="lastRhythm.beginDate"
            v-text="`Дата начала: ${lastRhythm.beginDate.format('dd.MM.y')}`"
        />
        <span class="font-weight-bold subheading" v-text="`Cтатус: ${status}`" />
    </v-layout>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { RhythmStatus, Rhythm } from '_M/rhythmItems';
@Component({ components: {} })
export default class AnalysisInfo extends Vue {
    get lastRhythm(): Rhythm {
        return this.$store.state.patient.lastRhythm;
    }
    get isEnded() {
        if (!this.lastRhythm) return false;
        return this.lastRhythm.status == RhythmStatus.Complete;
    }
    get status() {
        return !this.isEnded ? "В процессе" : "Завершён";
    }
}
</script>