import { Gender } from './common';

export class Patient {
    firstName?: string;
    lastName?: string;
    patronymic?: string;
    fullName?: string;
    birthDate?: Date;
    id?: number;
    email?: string;
    phone?: string;
    sex?: Gender

    constructor() {
        return {
            firstName: "",
            lastName: "",
            patronymic: "",
            get fullName() {
                let res = "";
                let lastName = this.lastName ? this.lastName : "";
                let firstName = this.firstName ? this.firstName : "";
                let patronymic = this.patronymic ? this.patronymic : "";
                res += lastName;
                res += firstName ? " " : "";
                res += firstName;
                res += patronymic ? " " : "";
                res += patronymic;
                return res;
            },
            set fullName(val) {
                let nameKeys = ["lastName", "firstName", "patronymic"];
                if (val) {
                    let nameParts = val.split(" ");
                    let i = 0;
                    for (let key of nameKeys) {
                        if (nameParts[i]) this[key] = nameParts[i++];
                        else this[key] = "";
                    }
                }
                else for (let key of nameKeys) this[key] = "";
            },
            birthDate: null,
            email: "",
            sex: 0
        }
    }
}

/** front-end use only */
export enum TimePreset {
    Now = "Сейчас",
    FiveMinAgo = "5 минут назад",
    HourAgo = "Час назад",
    Manual = "Указать"

}