<template>
    <v-layout column align-center justify-center>
        <h1>Помощь</h1>
        <v-expansion-panel class="ma-2" style="box-shadow: none; max-width: 600px;" v-model="panel">
            <v-expansion-panel-content
                v-for="(item, i) in helpItems"
                class="help-item-container block-shadow"
                :key="i"
            >
                <template slot="header">
                    <div style="display: flex; align-items: center;">
                        <div class="help-item-header-text">{{item.title}}</div>
                    </div>
                </template>
                <div class="help-item-content px-3 py-2">
                    <div v-for="(node, i) in item.content" :key="i" v-html="node"></div>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-layout>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({ components: {} })
export default class HelpScreen extends Vue {
    panel = 0;
    helpItems = [
        { 
            title: "Как ввести измерение",
            content: [
                "Для ввода измерений необходимо либо нажать кнопку  “+” в левом нижнем углу экрана, либо перейти по пункту меню “Ввести измерение”.",
                `<div class=my-3 style='display:flex;align-items:center;flex-flow:column;'>
                    <div class=v-speed-dial>
                        <button type='button' class='v-btn v-btn--floating v-btn--large theme--dark blue darken-2'>
                        <div class='v-btn__content'><i aria-hidden='true' class='v-icon mdi mdi-plus theme--dark'></i></div>
                        </button>
                    </div>
                    <span>Кнопка добавления измерений</span>
                </div>`,
                `Если ранее никаких изменений не вносилось, то система предложит указать дату начала исследования.
                Нужно указать день, в который был начат сбор измерений и нажать кнопку “Сохранить”.
                Этот день может быть раньше сегодняшнего.`,
                `<div class=my-4 style='display:flex;align-items:center;flex-flow:column;'>
                    <img src=`+require('@/assets/help/dateform.png')+`>
                    <span>Кнопка добавления измерений</span>
                </div>`,
                `Далее необходимо указать количество выделенного или выпитого с помощью ползунка либо ввести значение в поле ввода.
                Переключение “Выделено”/”Выпито” производится нажатием на кнопку в правом верхнем углу.`,
                `<div class=my-4 style='display:flex;align-items:center;flex-flow:column;'>
                    <img src=`+require('@/assets/help/inputs.png')+`>
                    <span>Кнопка добавления измерений</span>
                </div>`,
                `По умолчанию ввод объема осуществляется на текущий день и время.
                Если измерение было осуществлено ранее, но возможность внести измерение появилась позже, то необходимо указать день время измерений.
                Для этого необходимо нажать кнопку в нижней части формы и выбрать день/время.`,
                `<div class=my-4 style='display:flex;align-items:center;flex-flow:column;'>
                    <img src=`+require('@/assets/help/image3.png')+`>
                </div>`,
                `<hr/>`,
                `<div class=my-4 style='display:flex;align-items:center;flex-flow:column;'>
                    <img src=`+require('@/assets/help/image1.png')+`>
                </div>`,
                `После того как данные были успешно внесены необходимо нажать кнопку “Сохранить”.
                В случае успешного ввода будет показано соответствующее уведомление.`,
                `<div class=my-4 style='display:flex;align-items:center;flex-flow:column;'>
                    <div class="m-container" style='position:relative;left:0%;transform:unset'>
                        <div class="v-alert alert lighten-2 success">
                        <div class="opacity-background">
                        <i aria-hidden="true" class="v-icon mdi mdi-checkbox-marked-circle theme--light" style="font-size: 40px;"></i>
                        <span class="message">Ваше измерение успешно внесено, спасибо</span>
                        </div>
                        <i aria-hidden="true" class="v-icon close-message mdi mdi-close theme--light black--text" style="font-size: 24px;"></i>
                        </div>
                    </div>
                </div>`,
            ]
        },
        // { title: "Как ввести", content: "Очень просто, нужно всего лишь..." }
    ];
}
</script>
<style scoped lang="scss">

.help-item-container{
        &.block-shadow {
            box-shadow: 0px 2px 14px rgba(207, 207, 207, 0.5) !important;
        }
        &:hover{
            .help-item-header-text {
                color: #000;
            }
        }
        margin-bottom: 10px;
        .help-item-header-text {
            color: #4D4D4D;
            font-size: 18px;
            line-height: 21px;
            transition: color 150ms;
        }
        .help-item-content{
            background-color: #F9F9F9;
            word-break: break-word;
        }
    }
    .v-expansion-panel__container {
        border: none !important;
    }
    .v-expansion-panel__header{
        box-shadow: none;
        height: 42px;
    }
</style>