<template>
    <span>
        <v-dialog v-model="dialog" max-width="400">
            <v-card class="pa-3">
                <h2 class="mb-2">О сервисе Уритм</h2>Сервис по предоставлению услуги математической обработки данных ритма суточных мочеиспусканий. После оплаты, клиенту необходимо за двое суток указать время и объем потребленной жидкости, а также время и объем выделенной мочи.
                После обработки данных он получает математическую интерпретацию данного исследования, с которым может обратиться в врачу.
                <p
                    class="subheading mt-2 mb-0 font-weight-bold"
                >Стоимость услуги составляет 1000 рублей</p>
            </v-card>
        </v-dialog>
        <div>
            <span v-text="`ИП «Полунин Александ Леонидович» - `" />
            <a class="link" href="tel:+79051248913" v-text="`+79051248913`" />
            <span v-text="` - `" />
            <a class="link" href="mailto:alpolunin82@gmail.com" v-text="`alpolunin82@gmail.com`" />
        </div>
        <span v-text="`ИНН: 683307012087, ОГРНИП: 319682000045689`" />
        <div>
            <span @click="dialog = true" class="link" v-text="'Что мы делаем?'" />
            <span v-text="` - © 2019 Urythm`" />
        </div>
    </span>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({ components: {} })
export default class Footer extends Vue {
    dialog = false;
}
</script>