export default class ApiHelper {
    constructor() { }

    get apiUrls() {
        return {
            noUrl: "/api",
            localApiUrl: "http://localhost:7777/api",
            lanApiUrl: "http://192.168.0.104:7777/api",
            externalApiUrl: "https://uritm.ru/api",
        }
    }

    get jsonHeaders(): any {
        return { headers: { "Content-Type": "Application/json" } };
    }

    private get apiUrl() {
        // if (process.env.VUE_APP_ENV == "stage") return this.apiUrls.localapiUrl;
        if (process.env.VUE_APP_ENV == "prod") return this.apiUrls.externalApiUrl;
        // return this.apiUrls.localApiUrl;
        return this.apiUrls.localApiUrl;
    }

    combineUrl(leftPart: string): string {
        return `${this.apiUrl}${leftPart}`;
    }

    // public notificationUrl() {
    //     let wsUrl = this.container.wsNotifications;
    //     return wsUrl;
    // }

    auth = this.apiUrl + "/account"
    login = this.auth + "/auth"
    register = this.auth + "/register"
    resetPass = this.auth + "/resetPassword"

    doctor = this.apiUrl + "/doctor"
    createPatient = this.doctor + "/createPatient"
    createPatientWithoutReg = this.doctor + "/createPatientWithoutReg"
    searchPatients = this.doctor + "/findPatients"
    getRhythmResult = this.doctor + "/rhythmResult"
    getPatientList = this.doctor + "/listPatients"
    addAnalysis = this.doctor + "/addRhythm"

    patient = this.apiUrl + "/patient"
    addRhythmItem = this.patient + "/addItem"
    getLastRhythm = this.patient + "/lastRhythm"

    getBeginDateUrl = this.patient + "/setBeginDate"

    complete = this.patient + "/complete";
    removeMeasure = this.patient + "/removeMeasure";
    updateMeasure = this.patient +"/update";

    payment = this.apiUrl + "/Rhythm";
    getPayRhythm = this.payment + "/payment";


}