import Vue from 'vue';
import Vuex from 'vuex';
import { dashboardModule } from '@/store/modules/dashboardModule';
import { authModule } from '@/store/modules/authModule';
import { patientModule } from './modules/patientModule';
import { doctorModule } from './modules/doctorModule';
import { paymentModule } from './modules/paymentModule';

Vue.use(Vuex);

export class CommonState {
    sidebar: boolean = true;
    debugData: any = null;
}

export default new Vuex.Store({
    modules: {
        auth: authModule,
        doctor: doctorModule,
        patient: patientModule,
        dashboard: dashboardModule,
        payment: paymentModule
    },
    state: new CommonState(),
    mutations: {
        toggleSidebar(state: CommonState) {
            state.sidebar = !state.sidebar
        }
    },
    actions: {

    },
});
