import ApiHelper from '@/utils/ApiHelper';
import { httpQuery } from '@/main';

class PaymentState {
    rhythmInfo = {
        fullName: "",
        creationDate: null,
        email: "",
        phone: "",
        researchType:null
    }

    payUrl=""
}

export const paymentModule = {
    namespaced: true,
    state: new PaymentState(),

    mutations: {
        setInfo(state: PaymentState, data) {
            state.rhythmInfo.fullName = data.fullName;
            state.rhythmInfo.email = data.email;
            state.rhythmInfo.creationDate = new Date(data.researchDate);
            state.payUrl = data.payUrl;
            state.rhythmInfo.researchType = data.researchType
        }
    },

    actions: {
        async getRhythmInfo({ state, commit }, id: number) {
            let url = new ApiHelper().getPayRhythm;
            let resp = await httpQuery("get", `${url}/${id}`, null, null, id);
            if (!resp) return;
            if (resp.isSuccess) {
                commit("setInfo", resp.data);
                return resp.isSuccess;
            }
        }
    }
}