<template>
    <v-content class="login-bg dark" style="background: url(images/bg.jpg);">
        <v-container style="padding-bottom: 62px;" fill-height>
            <v-fade-transition hide-on-leave>
                <router-view />
            </v-fade-transition>
        </v-container>
        <div class="required-info">
            <v-dialog v-model="dialog" max-width="400">
                <v-card class="pa-3">
                    <h2 class="mb-2">� ������� �����</h2>
                    ������ �� �������������� ������ �������������� ��������� ������ ����� �������� ��������������. ����� ������, ������� ���������� �� ���� ����� ������� ����� � ����� ������������ ��������, � ����� ����� � ����� ���������� ����.
                    ����� ��������� ������ �� �������� �������������� ������������� ������� ������������, � ������� ����� ���������� � �����.

                    <!--<p class="subheading mt-2 mb-0 font-weight-bold">��������� ������ ���������� 1000 ������</p>-->
                </v-card>
            </v-dialog>
            <div>
                <!--<span v-text="`�� �������� �������� ����������� - `" />-->
                <!-- <span @click="dialog = true"
                      class="link"
                      v-text="'Что мы делаем?'" /> -->
            </div>
            <!--<span v-text="`���: 683307012087 ������: 319682000045689`" />
            <span v-text="`+79051248913`" />
            <span v-text="`alpolunin82@gmail.com`" />-->
            <span v-text="`(С) 2019 Urythm`" />
        </div>
    </v-content>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Footer from "../Home/Footer.vue";
@Component({ components: { "u-footer": Footer } })
export default class Auth extends Vue {
 dialog = false;
}
</script>
<style lang="scss">
.required-info {
    position: absolute;
    bottom: 4px;
    color: white;
    display: flex;
    flex-flow: column;
    line-height: 18px;
    text-align: center;
    width: 100%;
}
.login-bg {
    &::before {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #00000080;
    }
}
</style>