<template>
<v-toolbar card dense>
    <v-toolbar-title>
        <span class="subheading" v-text="inputTypeData.title"/>
    </v-toolbar-title>
    <v-spacer/>
    <v-btn v-if="isStarted" outline icon @click="switchType()">
        <v-icon v-text="inputTypeData.icon"/>
    </v-btn>
</v-toolbar>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({components: {}})
export default class InputHeader extends Vue {
    get rhythmItem() { return this.$store.state.patient.currentItem }

    get inputTypeData() {
        return {
            icon: this.rhythmItem.isIntake ? "mdi-food-fork-drink" : "mdi-toilet",
            title: !this.isStarted ? "Дата начала исследования" : this.rhythmItem.isIntake ? "Потреблено" : "Выделено"
        }
    }
    switchType() { this.rhythmItem.isIntake = !this.rhythmItem.isIntake }
    
    @Prop({ default: true }) isStarted: boolean;
    @Prop({ default: null }) qtype: string;
    @Watch("qtype") onTypeChange(val) { this.rhythmItem.isIntake = val=="in"; }
}
</script>