<template>
    <v-container>
        <v-layout style="margin: -8px" row wrap v-if="patients">
            <v-flex
                v-for="(patient, patCounter) in patients"
                :key="patCounter"
                class="pa-2" md12 lg6
            >
                <PatientCard :patient="patient.patient"/>
            </v-flex>
        </v-layout>
        <v-layout class="mt-3" justify-center>
            <v-btn color="primary" fab @click="go(-1)" :disabled="currPage==1">
                <v-icon size="36" v-text="'mdi-arrow-left-bold '"/>
            </v-btn>
            <v-btn color="primary" fab @click="go(1)" :disabled="!isNextPageAvailable">
                <v-icon size="36" v-text="'mdi-arrow-right-bold '"/>
            </v-btn>
        </v-layout>
    </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import PatientCard from './PatientCard.vue';
import DayData from './Details/DayData.vue';

@Component({ components: { PatientCard } })
export default class AnalysisList extends Vue {
    async created() {
        await this.$store.dispatch("doctor/getPatientList", 1); // 2nd arg is pageNum
    }

    currPage = 1;
    go(val) {
        if (val < 0 && this.currPage == 1) return;
        this.currPage += val;
        this.$store.dispatch("doctor/getPatientList", this.currPage);
    }

    get patients() {
        return this.$store.state.doctor.patientList;
    }

    get isNextPageAvailable() {
        return this.patients.length == 10;
    }
}
</script>