<template>
    <v-content class="login-bg" style="background: url(images/bg.jpg);">
        <v-container fill-height>
            <v-layout column align-center justify-center>
                <span
                    class="white--text title mb-3"
                    style="font-weight: 300"
                    v-text="'Задать пароль'"
                />
                <v-card class="pa-3" width="300" @keyup.enter="submit">

                    <v-form ref="passForm">
                        <v-text-field box label="Пароль" type="password" hide-details class="mb-2"
                            v-model="resetPassModel.password"
                            :rules="[Rules.requiredRule,Rules.isPassword]"
                        />
                        <v-text-field box label="Подтвердите пароль" type="password" hide-details class="mb-2"
                            v-model="confirmPassword"
                            :rules="[Rules.requiredRule,Rules.isPassword]"
                        />
                        <router-link to="/noapp/auth">Войти?</router-link>
                        <v-btn color="success" block @click="submit">Задать</v-btn>
                    </v-form>
                </v-card>
            </v-layout>
        </v-container>
    </v-content>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ValidationRules } from "@/utils/validate";

@Component({ components: {} })
export default class ResetPass extends Vue {
    @Prop({ default: "" }) userId: string;
    @Prop({ default: "" }) code: string;
    Rules = ValidationRules;
    confirmPassword: string = "";

    created() {
        this.$store.state.debugData = [this.resetPassModel];
        this.resetPassModel.id=this.userId;
        this.resetPassModel.code = this.code
    }

    resetPassModel = {
        password: "",
        id: null,
        code: null
    };
    loading = false;
    async submit() {
        if (!(this.$refs.passForm as any).validate()) return;
        if (this.resetPassModel.password != this.confirmPassword) {
            this.$message.add({ type: 1, text: "Пароли не совпадают. Попробуйте ещё раз." });
            return;
        }
        this.loading = true;
        let isSuccess = await this.$store.dispatch(
            "auth/resetPassword",
            this.resetPassModel
        );
        if (isSuccess) {
            this.$message.add({ type: 2, text: "Вы зарегистрировались. Войдите с помощью своих данных." });
            this.$router.push("/noapp/auth");
        }
        else {
            this.$message.add({ type: 1, text: "Произошла ошибка. Попробуйте ещё раз." });
        }
        this.loading = false;
    }
}
</script>