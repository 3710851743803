<template>
    <div class="w-100">
        <!-- <NewAnalysis v-if="!isAnyAnalysis" @buySuccess="isAnyAnalysis = true" />
        <template v-else> -->
            <DashControls />
            <AnalysisInfo />
            <History />
        <!-- </template> -->
    </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import AnalysisInfo from "./Patient/AnalysisInfo.vue";
import History from "./Patient/History/List.vue";
import DashControls from "./Patient/DashControls.vue";
import NewAnalysis from '../Patient/NewAnalysis/NewAnalysis.vue';

@Component({ components: { History, AnalysisInfo, DashControls, NewAnalysis } })
export default class PatientDashboard extends Vue {
    isAnyAnalysis = false;
}
</script>