<template>
    <v-container>
        <v-layout justify-center>
            <v-form ref="patReg">
                <v-card class="pa-4"
                        width="400"
                        min-width="300">
                    <v-layout justify-space-between
                              align-center>
                        <span class="title"
                              v-text="'Создание исследования'" />
                        <v-btn class="mr-0"
                               v-if="isNewPatient"
                               small
                               outline
                               @click="setSearchMode()"
                               v-text="'К поиску'" />
                    </v-layout>
                    <v-layout class="mt-2"
                              align-center>
                        <v-autocomplete v-if="!isNewPatient"
                                        v-model="patient"
                                        :items.sync="patients"
                                        item-text="fullName"
                                        :loading="isLoading"
                                        :search-input.sync="searchInput"
                                        return-object
                                        label="ФИО"
                                        ref="patSelect">
                            <template v-if="!isNewPatient"
                                      v-slot:prepend-item>
                                <v-list-tile @click="setNewPatient()">
                                    <v-icon v-text="'mdi-plus'"
                                            class="mr-2" />Добавить нового пациента
                                </v-list-tile>
                            </template>
                            <template v-slot:no-data>
                                <v-list-tile>
                                    <span style="font-size:13px;"
                                          v-text="`Пациенты с таким именем отсуствуют`" />
                                </v-list-tile>
                            </template>
                        </v-autocomplete>
                        <v-text-field v-else
                                      :value="patient.fullName"
                                      @blur="(evt) => patient.fullName = evt.target.value"
                                      label="ФИО"
                                      :rules="[Rules.requiredRule]" />
                        <v-btn-toggle v-if="isNewPatient"
                                      v-model="patient.sex"
                                      style="margin: 6px 0px;">
                            <v-btn outline
                                   :value="Gender.Male">М</v-btn>
                            <v-btn outline
                                   :value="Gender.Female">Ж</v-btn>
                        </v-btn-toggle>
                    </v-layout>
                    <span v-if="!isNewPatient && !!patient"
                          v-text="`Пациент: ${patient.fullName}, ${getYears(patient.birthDate)}`" />
                    <v-expand-transition>
                        <div v-if="isNewPatient">
                            <v-text-field v-model="patient.phone"
                                          :rules="[Rules.requiredRule, Rules.isPhone]"
                                          label="Телефон"
                                          mask="+7-###-###-##-##" />
                            <v-text-field v-model="patient.email"
                                          :rules="[Rules.requiredRule, Rules.isEmail]"
                                          label="Е-mail" />
                            <DateField label="Дата рождения"
                                       v-model="patient.birthDate"
                                       :rules="[Rules.requiredRule]"
                                       :max="new Date().toISOString().substring(0, 10)" />
                        </div>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-select v-if="isNewPatient || patient"
                                  :items="researchType"
                                  item-text="title" item-value="val"
                                  label="Тип исследования"
                                  v-model="selectedResearchType" />
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-select v-if="isNewPatient || patient"
                                  :items="payTypes"
                                  item-text="title" item-value="val"
                                  label="Способ оплаты"
                                  v-model="selectedPayType" />
                    </v-expand-transition>
                    <v-expand-transition>
                        <div v-if="isError">Укажите пол пациента справа от ФИО</div>
                    </v-expand-transition>
                    <v-btn :loading="isLoading"
                           :disabled="!valid || isLoading"
                           block
                           color="success"
                           class="mt-4"
                           @click="addAnalysis()"
                           v-text="!isNewPatient ? `Добавить анализ` : `Зарегистрировать`" />
                </v-card>
            </v-form>
        </v-layout>
    </v-container>
</template>

<script lang="ts">
import { Vue, Prop, Watch, Component } from "vue-property-decorator";
import { Gender } from "@/model/common";
import { Patient } from "@/model/patient";
import { ValidationRules } from "@/utils/validate";
import DateField from "_C/shared/DateField.vue";
import { getPlural } from "@/plugins/utils";
import { differenceInCalendarYears } from "date-fns";

@Component({ components: { DateField } })
export default class AddAnalysis extends Vue {
    Gender = Gender;
    Rules = ValidationRules;
    isLoading = false;
    isNewPatient = false;
    isError = false;
    valid = true;
    withoutReg = false;

    payTypes = [{ title: "Наличные", val: 1 }, { title: "Ссылка на оплату", val: 2 }];
    researchType = [{ title: "РСМ", val: 1 }, { title: "ДУЗИ", val: 2 }, { title: "УЗИ планеометрия", val: 3 } ]

    selectedPayType: number = 1
    selectedResearchType: number=1

    created() {
        this.patient = null;
    }

    /** Currently created or edited patient */
    get patient(): Patient {
        return this.$store.state.doctor.newPatient;
    }
    set patient(val) {
        this.$store.state.doctor.newPatient = val;
    }

    /** List of patients for autocomplete list */
    get patients() {
        return this.$store.state.doctor.foundPatients;
    }

    /** Search value for v-autocomplete */
    searchInput: string = null;

    /** Search for patients by name */
    @Watch("searchInput")
    async doSearch(val) {
        if (this.isLoading) return;
        this.isLoading = true;
        try {
            await this.$store.dispatch(
                "doctor/searchPatients",
                this.searchInput
            );
        } catch {
            this.isLoading = false;
        }
        this.isLoading = false;
    }

    @Watch("patient", { deep: true }) onDataChange() {
        this.valid = true;
    }

    /** Create new patient if existing was not found */
    setNewPatient() {
        this.patient = new Patient();
        this.patient.fullName = this.searchInput;
        this.isNewPatient = true;
    }

    /** set search mode */
    setSearchMode() {
        this.patient = null;
        this.isNewPatient = false;
    }

    /** validates elements with and without rules */
    validateForm() {
        let formValid = (this.$refs["patReg"] as any).validate();
        var result = this.patient.sex != null && formValid;
        this.isError = this.patient.sex == null;
        this.valid = result;
        return result;
    }

    /** Saves new analysis for existing patient */
    async addAnalysis() {
        this.isLoading = true;
        if (this.validateForm() || !this.isNewPatient) {
            let isSuccess = false;
            if (!this.isNewPatient) {
                isSuccess = await this.$store.dispatch(
                    "doctor/addAnalysis",
                    { patientId: this.patient.id, paymentType: this.selectedPayType, type: this.selectedResearchType }
                );
            //} else if (this.withoutReg) {
            //    isSuccess = await this.$store.dispatch(
            //        "doctor/addPatientWithoutReg",
            //        this.patient
            //    );
            } else {
                isSuccess = await this.$store.dispatch(
                    "doctor/addPatient",
                    { patient: this.patient, paymentType: this.selectedPayType, type: this.selectedResearchType }
                );
            }
            this.showResult(isSuccess);
        }
        this.isLoading = false;
    }

    showResult(isSuccess) {
        if (isSuccess) {
            let msg = "Исследование успешно создано";
            if (this.isNewPatient)
                msg +=
                    ". Пациенту выслано на e-mail приглашение на регистрацию.";
            this.$message.add({
                type: 2,
                text: msg
            });
            this.$router.push("/app/analyzes");
        } else {
            this.$message.add({
                type: 1,
                text: "Произошла ошибка при создании исследования."
            });
        }
    }

    getYears(birthDate: Date) {
        let years = differenceInCalendarYears(new Date(), birthDate);
        return `${years} ${getPlural(years, "год", "лет", "года")}`;
    }
}
</script>
<style lang="scss">
.lift-hoverable {
    padding: 10px;
    margin: 0px -10px;
    box-shadow: 0px 0px 0px 0px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    &:hover {
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
            0px 7px 10px 1px rgba(0, 0, 0, 0.14),
            0px 2px 16px 1px rgba(0, 0, 0, 0.12) !important;
    }
}
</style>