<template>
    <div class="w-100">
        <PatientDashboard v-if="isPatient" />
        <AnalysisList v-if="isDoctor" />
    </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { UserType } from "@/model/common";
import PatientDashboard from "./PatientDashboard.vue";
import AnalysisList from '_C/Analysis/AnalysisList.vue';
import NewAnalysis from '_C/Patient/NewAnalysis/NewAnalysis.vue';

@Component({ components: { PatientDashboard, AnalysisList, NewAnalysis } })
export default class DashboardScreen extends Vue {
    get currUserType() {
        let currUser = this.$store.state.auth.currentUser;
        if (!currUser) return null;
        return currUser.userType;
    }
    get isPatient() {
        return this.currUserType == UserType.Patient;
    }
    get isDoctor() {
        return this.currUserType == UserType.Doctor;
    }


}
</script>