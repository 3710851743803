<template>
    <v-layout justify-center>
        <v-card
            width="400"
            class="pa-3"
        >
            <h2>Ссылка на оплату</h2>
            <v-text-field v-model="payLink" ref="paylinkInp" />
            <v-btn
                color="success"
                block
                @click="openPayLink"
            >Копировать</v-btn>
        </v-card>
        <!-- <v-scroll-y-transition group hide-on-leave>
            <v-card v-if="needNew" key="main" class="px-4 py-3">
                <v-layout align-center justify-center column>
                    <span
                        class="headline"
                        v-text="`На данный момент у вас нет ни одного исследования`"
                    />
                    <span
                        class="subheading"
                        v-text="`Вы можете его создать нажав на кнопку 'Создать исследование'`"
                    />

                    <v-btn @click="needNew = false" large dark color="blue">
                        <v-icon class="mr-2">mdi-plus-circle</v-icon>
                        <span v-text="'Создать исследование'" />
                    </v-btn>
                </v-layout>
            </v-card>
            <v-card v-else key="checkout" class="px-4 py-3">
                <v-layout align-center justify-center column>
                    <span
                        class="headline"
                        v-text="`Для того чтобы создать исследование, нужно его оплатить`"
                    />
                    <span class="subheading" v-text="'Стоимость услуги 1330 ₽'" />
                    <v-btn @click="payDialog = true" large dark color="blue">
                        <v-icon class="mr-2">mdi-cart-outline</v-icon>
                        <span v-text="'Перейти к оплате'" />
                    </v-btn>
                </v-layout>
            </v-card>
        </v-scroll-y-transition>
        <v-dialog width="600" v-model="payDialog">
            <v-card class="pa-2">
                <span v-text="'Уритм Банк Эквайринг~'" />

                <v-layout class="ma-3 text-xs-center" align-center justify-center column>
                    <span class="subheading" v-text="'Название услуги:'" />
                    <span class="mt-1 title" v-text="'Исследование ритма суточных мочеиспусканий'" />
                    <v-divider class="mt-3 mb-2 w-100" />
                    <span class="subheading" v-text="'Стоимость:'" />
                    <span class="mt-1 headline" v-text="'1330 ₽'" />

                    <v-btn
                        @click="processPayment()"
                        large
                        dark
                        class="w-100 mx-0"
                        color="blue"
                    >Оплатить</v-btn>
                </v-layout>
            </v-card>
        </v-dialog> -->
    </v-layout>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { UserType } from "@/model/common";

@Component
export default class NewAnalysis extends Vue {

    get payLink() {
        if (this.$store.state.doctor.payLink) {
            return "https://uritm.ru/#/noapp/payment/" + this.$store.state.doctor.rythmId;//this.$store.state.doctor.
        }

        return "";
    }


    openPayLink() {
        //window.open(this.payLink, "_blank");
        let inpRef:any = this.$refs.paylinkInp;

        inpRef.$el.querySelector("input").select();
        document.execCommand("copy");
    }
    // needNew = true;
    // payDialog = false;
    // processPayment() {
    //     this.payDialog = false;
    //     this.$message.add({
    //         type: 2,
    //         text:
    //             "Оплата платёжа успешно подтверждена, вы получили доступ к новому исследованию"
    //     });

    //     this.$emit("buySuccess");
    // }
}
</script>