<template>
    <v-content class="login-bg" style="background: url(images/bg.jpg);">
        <v-container fill-height>
            <v-layout v-if="!isError" column align-center justify-center>
                <v-card max-width="500" width="100%" class="pa-3">
                    <h2>Оплата анализа №{{ id }}</h2>

                    <v-layout column class="mt-2" v-if="!isLoading">
                        <div>
                            <label class="pay-info-label" v-text="'ФИО:'" />
                            <span v-text="analysis.fullName" />
                        </div>
                        <div>
                            <label class="pay-info-label" v-text="'Email:'" />
                            <span v-text="analysis.email" />
                        </div>
                        <div>
                            <label class="pay-info-label" v-text="'Телефон:'" />
                            <span v-text="analysis.phone" />
                        </div>
                        <div>
                            <label
                                class="pay-info-label"
                                v-text="'Дата анализа:'"
                            />
                            <span
                                v-text="analysis.creationDate.format('dd.MM.y')"
                            />
                        </div>
                    </v-layout>
                    <!--<v-checkbox hide-details v-model="personalDataAgreement" label="Согласие на обработку персональных данных"/>-->
                    <v-layout
                        style="font-size: 16px;"
                        :column="$rs.br.smAndDown"
                        :align-center="!$rs.br.smAndDown"
                        :align-start="$rs.br.smAndDown"
                    >
                        <v-checkbox
                            v-model="offerAgreement"
                            style="flex: none;"
                            label="Ознакомлен с"
                            :hide-details="$rs.br.smAndDown"
                        />
                        <span
                            @click="dialog = true"
                            class="link ml-1 mb-1"
                            v-text="'договором публичной оферты'"
                        />
                    </v-layout>
                    <v-btn
                        :disabled="!personalDataAgreement || !offerAgreement"
                        color="success"
                        block
                        @click="openPayLink"
                        >Оплатить</v-btn
                    >
                </v-card>
            </v-layout>
            <v-layout v-else column align-center justify-center>
                <v-card width="430" class="pa-3">
                    <h2 class="text-xs-center">
                        Произошла ошибка, попробуйте позже.
                    </h2>
                </v-card>
            </v-layout>
            <v-dialog :fullscreen="$rs.br.smAndDown" v-model="dialog">
                <v-card :class="$rs.br.smAndDown ? 'pa-2' : 'pa-4'">
                    <v-icon
                        @click="dialog = false"
                        size="24"
                        class="close-btn"
                        color="black"
                        v-text="'mdi-close'"
                        :style="$rs.br.smAndDown ? 'position: fixed' : ''"
                    />
                    <!--<PublicOffer />-->
                    <component :is="publicOffer"></component>

                    <!--<v-layout class="mt-4">
                        <v-btn
                            @click="dialog=false;offerAgreement=true"
                            class="mx-2"
                            block
                            dark
                            color="green"
                        >
                            Принять
                        </v-btn>
                        <v-btn
                            @click="dialog=false;offerAgreement=false"
                            block
                            class="mx-2"
                        >
                            Отклонить
                        </v-btn>
                    </v-layout>-->
                </v-card>
            </v-dialog>
        </v-container>
    </v-content>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { UserType } from "@/model/common";
import PublicOffer from "./PublicOffer.vue";
import PublicOfferDuzi from "./PublicOfferDuzi.vue";
import PublicOfferUzip from "./PublicOfferUzip.vue";

@Component({ components: { PublicOffer, PublicOfferDuzi, PublicOfferUzip} })
export default class Payment extends Vue {
    @Prop({ default: null }) id;
    isError = false;
    personalDataAgreement = true;
    offerAgreement = false;
    dialog = false;

    isLoading = true;

    get analysis() {
        return this.$store.state.payment.rhythmInfo;
    }
    async created() {
        let id = Number.parseInt(this.id);
        if (isNaN(id)) this.isError = true;
        else {
            let isSuccess = await this.$store.dispatch(
                "payment/getRhythmInfo",
                id
            );
            // if (!isSuccess) this.isError = true;
            this.isLoading = false;
            return;
        }
    }

    get publicOffer() {
        let offerComponents = {
            1: "PublicOffer",
            2: "PublicOfferDuzi",
            3: "PublicOfferUzip"
        }

        let researchType = this.$store.state.payment.rhythmInfo.researchType;
        if (!researchType) return "PublicOffer";

        return offerComponents[researchType];
    }

    get payLink() {
        return this.$store.state.payment.payUrl;
    }

    openPayLink() {
        window.open(this.payLink, "_blank");
        // let inpRef: any = this.$refs.paylinkInp;

        // inpRef.$el.querySelector("input").select();
        // document.execCommand("copy");
    }
}
</script>
<style lang="scss">
.pay-info-label {
    color: #777;
    font-weight: 700;
    margin-right: 6px;
}
</style>