import axios, { AxiosPromise, AxiosRequestConfig } from "axios"
import { DefaultMapping } from './ObjMapper';

export interface AuthResponse {
    success: boolean;
    token?: string;
    error?: string;
}

export interface HttpQuery extends Function {
    (method: string, url: string, params?: any, headers?: any, data?: any): Promise<any>
}

export function HttpQueryFactory(getAuthToken: Function, globalError: ((err: any) => void)): HttpQuery //тут еще должен быть обработчик для отказов
{
    return async function (method: string, url: string, params?: any, headers?: any, data?: any): Promise<any> {
        let defaultHeaders: any = {
            "post": { "Content-Type": "application/json;charset=UTF-8" },
            "put": { "Content-Type": "application/json;charset=UTF-8" },
            "delete": { "Content-Type": "application/json;charset=UTF-8" }
        }

        let authToken = getAuthToken();

        let allheaders = {
            ...headers,
            ...defaultHeaders[method.toLowerCase()],
        };
        if (authToken) {
            allheaders["Authorization"] = `Bearer ${authToken}`
        }
        try {
            let res = await axios.request(<AxiosRequestConfig>{
                url: url,
                method: method,
                params: params,
                headers: allheaders,
                data: JSON.stringify(data)
            });
            return DefaultMapping(res.data);
        }
        catch (exc) {
            console.log(exc, exc.response);
            globalError(exc.response);
            throw exc;
        }
        //тут будет проверка ответа на допустимость
    };
}