export enum UserType {
    Doctor = 1,
    Patient
}

export enum Gender {
    Male,
    Female
}

export interface User {
    id: number;
    fio: string;
    birthDate: Date;
    type: UserType;
    sex: Gender;
}