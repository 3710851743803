import { User } from "@/model/common";
import { CommonState } from '@/store/store';
import { Module } from 'vuex';
import { httpQuery } from '@/main';
import ApiHelper from '@/utils/ApiHelper';

class AuthModuleState {
    isAuthorized = false;
    currentUser: User = null
}

export const authModule: Module<AuthModuleState, CommonState> = {
    namespaced: true,
    state: new AuthModuleState(),
    mutations: {
        setAuthorized(state: AuthModuleState) {
            state.isAuthorized = !!window.localStorage.getItem('rhythmtoken');
        },
        setUser(state:AuthModuleState, user: User) {
            state.currentUser = user;
        }
    },
    actions: {
        async authorize({ commit, dispatch }, payload: { login: string, pass: string }) {
            let url = new ApiHelper().login;
            let resp = await httpQuery("post", url, null, null, { login: payload.login, password: payload.pass });
            if (!resp) return false;

            if (resp.isSuccess) {
                window.localStorage.setItem('rhythmtoken', resp.data);
                dispatch("checkAuth");
                return true;
            }
            return false;
        },
        async loadCurrentUser({ commit }) {
            let url = new ApiHelper().combineUrl("/account/currUser");
            let resp = await httpQuery("get", url);
            if (!resp) return null;
            if (!resp.isSuccess) return null;
            if (resp.isSuccess) {
                commit("setUser", resp.data);
                return resp.user;
            }
        },
        async register({ commit, dispatch }, registerModel) {
            let url = new ApiHelper().register;
            let resp = await httpQuery("post", url, null, null, registerModel);
            if (!resp) return false;
            return resp.isSuccess;
        },
        async resetPassword({state}, resetPassModel) {
            let url = new ApiHelper().resetPass;
            let resp = await  httpQuery("post", url, null, null, resetPassModel);
            if (!resp) return false;
            return resp.isSuccess;
        },
        checkAuth({ commit }) {
            //проверяем имеющийся токен (на наличие)

            let existingToken = window.localStorage.getItem('rhythmtoken');
            if (existingToken) commit("setAuthorized");
        }
    }
}