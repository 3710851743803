<template>
<v-card-text>
    <v-layout align-center justify-space-between mb-3>
        <div>
            <v-text-field
                @keydown.down="rhythmItem.volume--"
                @keydown.up="rhythmItem.volume++"
                v-model="rhythmItem.volume"
                hide-details solo
                suffix="мл" mask="###"
                class="volume-input"
            />
        </div>
        <v-btn large class="my-3" color="success" dark depressed
            @click="save"
            :disabled="submitDisabled"
        >
            <v-fade-transition group>
                <span v-text="'Сохранить'" key="savename"/>
                <v-icon v-if="saved" key="saveicon">mdi-check-bold</v-icon>
            </v-fade-transition>
        </v-btn>
    </v-layout>
    <v-layout align-center>
        <v-btn
            @click="rhythmItem.volume--" key="inc"
            :disabled="disabled"
            class="incdec-btn" outline
        >
            <v-icon v-text="'mdi-minus'"/>
        </v-btn>
        <v-slider class="mt-0 mx-3" hide-details always-dirty
            v-model="rhythmItem.volume"
            min="0" :max="maxVol"
            :disabled="disabled"
        />
        <v-btn
            @click="rhythmItem.volume++" key="dec"
            class="incdec-btn" outline
            :disabled="disabled"
        >
            <v-icon v-text="'mdi-plus'"/>
        </v-btn>
    </v-layout>
</v-card-text>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({components: {}})
export default class VolumeInput extends Vue {
    get rhythmItem() { return this.$store.state.patient.currentItem }
    get submitDisabled() { return this.disabled || this.rhythmItem.volume == 0; }

    @Prop({default: false}) disabled: boolean;

    get maxVol() {
        return 999;
        // this.$store.state.auth.currentUser.birthDate;
        // Формула расчёта макс объёма для возраста нужен
        // Нужно с бэка по возрасту максимум подхватывать, нужно для UX онли
        // Или поместить сюда словарь с возраст-обьёмом
    }
    saved = false;
    save() {
        this.$emit('save');
        this.saved = true;
    }

}
</script>