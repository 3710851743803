<template>
    <div id="app">
        <v-app id="inspire">
            <DebugOverlay :dark="true"/>
            <router-view/>
        </v-app>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import DebugOverlay from '_C/shared/DebugOverlay.vue';

@Component({components:{DebugOverlay}})
export default class NoApp extends Vue {}
</script>
