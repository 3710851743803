<template>
    <v-layout>
        <v-layout column align-center class="text-xs-center grey lighten-2">
            <v-flex
                v-text="$rs.br.smAndDown ? 'Гипер' : 'Гиперрефлексия'"
                style="writing-mode: vertical-lr;transform: rotate(180deg);"
            />
            <v-spacer />
            <v-flex
                v-text="$rs.br.smAndDown ? 'Гипо' : 'Гипорефлексия'"
                style="writing-mode: vertical-lr;transform: rotate(180deg);"
            />
        </v-layout>
        <v-layout column align-center class="text-xs-center grey lighten-2">
            <v-flex v-text="'Ⅳ'" class="header details-flex red" />
            <v-flex v-text="'Ⅲ'" class="header details-flex orange" />
            <v-flex v-text="'Ⅱ'" class="header details-flex yellow" />
            <v-flex v-text="'Ⅰ'" class="header details-flex lime" />

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-flex
                        style="margin-top: auto;"
                        class="header details-flex green"
                        v-text="'Н'"
                        v-on="on"
                    />
                </template>
                <span>Норма</span>
            </v-tooltip>

            <v-flex v-text="'Ⅰ'" class="header details-flex lime" />
            <v-flex v-text="'Ⅱ'" class="header details-flex yellow" />
            <v-flex v-text="'Ⅲ'" class="header details-flex orange" />
            <v-flex v-text="'Ⅳ'" class="header details-flex red" />
        </v-layout>
    </v-layout>
</template>
<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";

@Component
export default class DetailsHeader extends Vue {
    // @Prop({ default: null }) patient;
}
</script>
<style lang="scss">
.details-flex {
    min-height: 25px;
    line-height: 25px;
    &.header {
        width: 30px;
        justify-content: center;
    }
    align-items: center;
    flex-basis: 25px !important;
    padding: 2px;
    display: flex;
}
.divider-flex {
    border-left: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #aaa;
    height: 100%;
    top: 0;
    padding: 10px 0px;
    flex: 0 !important;
}
</style>