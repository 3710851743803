<template>
    <div class="w-100">
        <v-btn
            v-if="!print && isAnythingToShow"
            :class="$rs.br.smAndDown ? 'print-btn-sm' : 'print-btn'"
            @click="printAnalysis()"
        >
            Распечатать
            <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-layout v-if="isAnythingToShow" ref="printMe" class="print-me" column>
            <v-layout row justify-center align-stretch wrap>
                <v-layout column
                    v-for="(result, i) in results"
                    :key="i"
                    :class="$rs.br.smAndDown ? 'mx-0 pa-2' : 'mx-2 pa-3'"
                    :width="$rs.br.smAndDown ? '100%' : ''"
                    style="overflow-x: overflow;"
                >
                    <template v-if="!!result">
                        <v-layout style="flex:0 1 0%" column justify-center align-center>
                            <div
                                class="subheading"
                            >{{result.patientName}}, {{getYears(result.patientBirthDate)}}</div>
                            <v-spacer />
                        </v-layout>
                        <DayData style="flex:1 1 0%" :days="result.days" />
                        <v-layout style="flex: none" :column="$rs.br.xsOnly" class="text-xs-center mt-2">
                            <v-flex>
                                <!-- <DetailsTable :days="result.days" /> -->
                            </v-flex>
                        </v-layout>
                    </template>
                    <div v-else>Подробности отсутствуют</div>
                </v-layout>
            </v-layout>
            <v-card
                v-if="!isSummariesEmpty"
                class="mt-2"
                :width="$rs.br.smAndDown ? '100%' : 500"
                style="margin: auto;"
            >
                <v-layout v-if="results && results.length > 0" column justify-center align-center>
                    <div
                        class="subheading mt-3"
                    >{{results[0].patientName}}, {{getYears(results[0].patientBirthDate)}}</div>
                    <v-flex v-for="(result, i) in results" :key="i+'sum'" class="w-100">
                        <div
                            v-if="result.days[0].items.length > 0"
                            class="subheading font-weight-light text-xs-center mt-2"
                            v-text="`${result.days[0].items[0].creationDate.format('dd.MM.y')}`"
                        ></div>
                        <SummaryTable
                            style="margin:auto"
                            :style="{width: $rs.br.smAndDown ? '100%' : '500px'}"
                            :summary="result.summary"
                        />
                    </v-flex>
                </v-layout>
            </v-card>
        </v-layout>
        <v-layout v-else align-center justify-center column>
            <v-card class="pa-3 title">
                <v-layout column>
                    <span v-text="'Данные об анализах отсутствуют'" />
                    <v-btn color="primary" class="mx-0 mt-3" @click="$router.go(-1)">
                        <v-icon v-text="'mdi-arrow-left-bold'" class="mr-2" />
                        <span v-text="'Назад'" />
                    </v-btn>
                </v-layout>
            </v-card>
            <NewAnalysis v-if="newAnalysis" @buySuccess="createNewAnalysis" class="mt-4" />
        </v-layout>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import SummaryTable from "./Details/SummaryTable.vue";
import DetailsTable from "./Details/DetailsTable.vue";
import DayData from "./Details/DayData.vue";
import { differenceInCalendarYears } from "date-fns";
import { getPlural } from "@/plugins/utils";
import { Printd } from "printd";
import NewAnalysis from "../Patient/NewAnalysis/NewAnalysis.vue";

@Component({ components: { SummaryTable, DetailsTable, DayData, NewAnalysis } })
export default class PatientFullCard extends Vue {
    private printer: Printd = new Printd();

    @Prop({ default: null }) id;
    // autopring
    @Prop({ default: false }) print;

    newAnalysis = true;

    //createNewAnalysis() {
    //    this.newAnalysis = false;
    //    // this.$store.dispatch("get new analysis")
    //    // await this.$store.dispatch("doctor/getRhythmResult", id);
    //}

    get isAnythingToShow() {
        if (!this.results) return false;
        if (this.results.length == 0) return false;
        let isAnyDays = false;
        this.results.forEach(result => {
            if (result.days && result.days.length > 0) isAnyDays = true;
        });
        if (!isAnyDays) return false;
        return true;
    }

    get results() {
        let results = this.$store.state.doctor.rhythmResult;
        if (!results) return null;
        return results.sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );
    }

    get isSummariesEmpty() {
        let isSummEmpty = true;
        if (this.results) {
            this.results.forEach(result => {
                if (result.summary.filter(x => x.quantity != 0).length > 0)
                    isSummEmpty = false;
            });
        }
        return isSummEmpty;
    }

    async created() {
        let id = Number.parseInt(this.id);
        if (isNaN(id)) return;

        await this.$store.dispatch("doctor/getRhythmResult", id);

        console.log("print?", this.print);
        if (this.print) {
            setTimeout(() => this.$nextTick(() => this.printAnalysis()), 2000);
        }
    }

    mounted() {
        //console.log("print?", this.print);
        //if (this.print) {
        //    setTimeout(() => this.$nextTick(() => this.printAnalysis()), 2000);
        //}
    }


    getYears(birthDate) {
        function calculateAge(birthday) { // birthday is a date
            var ageDifMs = Date.now() - birthday;
            var ageDate = new Date(ageDifMs); // miliseconds from epoch
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }

        let years = calculateAge(birthDate);//differenceInCalendarYears(new Date(), birthDate);
        return `${years} ${getPlural(years, "год", "лет", "года")}`;
    }

    printAnalysis() {
        let css = [];
        let stylesHtml = "";
        for (const node of [...document.querySelectorAll("link[rel=stylesheet")]) {
            css.push(node.getAttribute("href"));
        }
        for (const node of [...document.querySelectorAll("style")]) {
            stylesHtml += node.outerHTML;
        }
        css.push(stylesHtml);
        let printCss = `
            .print-me { font-family: sans-serif; }
            .details-flex {
                min-height: 25px;
                line-height: 25px;
                align-items: center;
                flex-basis: 25px !important;
                padding: 2px;
                display: flex;
            }
            .details-flex.header {
                width: 30px;
                justify-content: center;
            }
            .deviation-cell {
                border-right: 1px solid #777;
                font-size: 13px;
                padding-right: 2px;
                margin-right: 1px;
            }
            @media print {
                .layout {
                    -webkit-print-color-adjust: exact;
                }
                .details-flex {
                    border-left: 1px solid;
                    border-right: 1px solid;
                    background: currentColor;
                }
            }
        `;
        css.push(printCss);
        console.log(css);
        let scriptHead = "";
        for (const node of [...document.querySelectorAll("script")]) {
            scriptHead += node.outerHTML;
        }
        this.readyPrint(() =>
            this.printer.print(this.$refs["printMe"] as any, css)
        );
    }

    readyPrint(cb) {
        this.$nextTick(() => {
            if (this.$refs["printMe"] === undefined)
                setTimeout(() => {
                    this.readyPrint(cb);
                }, 300);
            else cb();
        });
    }
}
</script>
<style lang="scss">
.print-btn:not(#not) {
    z-index: 2;
    position: absolute !important;
    left: 20px;
    top: 20px;
}
.print-btn-sm {
    position: relative !important;
}
</style>