import { stringIsNullOrBlank, stringIsNullOrEmpty } from "_U/strings";
export class ValidationRules {

    //errors
    public static abstractText: string = "Значение введено не верно";
    public static errorPasswordMassage: string = "Пароль должен быть длинее 6 символов и содержать минимум 1 цифру";
    public static leftLettersMessage: string = "Посторонние символы";

    //abstract
    public static requiredRule = (v: any) => !!v || v === 0 || "Обязательно к заполнению";
    public static isNumRule = (v: any) => !isNaN(v) || "Значение не является числом";
    public static requiredStrRule = (v: any) => !stringIsNullOrBlank(v) || "Обязательно к заполнению";
    public static dateRule = function (v: any) {
        if (stringIsNullOrEmpty(v)) return "Обязательно к заполнению";
        if (v.match(/[0-3][0-9][.][0-1][0-9][.](19|20)([0-9]{2})/) == null || v == "Invalid Date") return "Неверный формат даты";
        else return true;
    };

    public static isAlphanumeric = (v: string) => /^[a-zA-Z0-9]*$/.test(v) || ValidationRules.abstractText;
    public static isNumeric = (v: string) => /^\d*$/.test(v) || ValidationRules.abstractText;
    public static isCyrillic = (v: any) => /^[а-яА-ЯёЁ]*$/.test(v) || ValidationRules.leftLettersMessage;
    public static isCyrillicAndNumbers = (v: any) => /^[а-яА-ЯёЁ0-9]*$/.test(v) || ValidationRules.leftLettersMessage;
    public static isLatin = (v: any) => /^[a-zA-Z]*$/.test(v) || ValidationRules.leftLettersMessage;
    public static isLetters = (v: any) => /^[а-яА-Яa-zA-ZёЁ]*$/.test(v) || ValidationRules.leftLettersMessage;
    public static isLettersAndNumber = (v: any) => /^[а-яА-Яa-zA-Z0-9ёЁ]*$/.test(v) || ValidationRules.leftLettersMessage;
    public static isWithoutSigns = (v: any) => /^[^\W]*$/.test(v) || ValidationRules.isLettersAndNumber(v) || ValidationRules.leftLettersMessage;
    public static isWithoutNumbers = (v: any) => /^\D*$/.test(v) || ValidationRules.leftLettersMessage;

    public static systemCountLimit = (v: string) => v.length < 40 || "Превышено разрешённое количество символов";

    public static dateMaxControl = function (max: any, min: any, strictly: boolean = true) {
        //mb to do
        if (typeof max !== typeof min) return "Несовпадение типов";

        let comparison = null;
        if (strictly) comparison = function (v1, v2) { return v1 > v2 };
        else comparison = function (v1, v2) { return v1 >= v2 };


        if (typeof max == "number" || typeof max == "string" || typeof max == "boolean") {
            if (comparison(max, min)) return true;
            else return ValidationRules.abstractText;
        }
        if (typeof max == "object" && typeof ValidationRules.dateRule(max) == "boolean" && typeof ValidationRules.dateRule(min) == "boolean") {
            if (comparison((max as Date).getTime(), (min as Date).getTime())) return true;
            else return ValidationRules.abstractText;
        }
        return "Сравнение невозможно"
    };

    //contacts
    public static isEmail = (v: string) => !v || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Email введен не верно";
    public static isPhone = (v: string) => !v || /(^['+']\d{11}$)|(^\d{11}$)/.test(v) || "Телефон введен не правильно";
    public static isPhoneWithOutCode = (v: string) => /^\d{10}$/.test(v) || "Телефон введен не правильно";
    public static isHref = (v: string) => /^(http|https):\/\/\S{2,255}\S$/.test(v) || "Ссылка введена не верно";
    public static isPassword = (v: string) => !!v && v.length >= 6 || ValidationRules.errorPasswordMassage;

    //documents
    public static isSnils = (v: string) => /^\d{11}$/.test(v) || "Снилс введен не верно";
    public static isFisINN = (v: string) => /^\d{12}$/.test(v) || "ИНН введен не верно";
    public static isJurINN = (v: string) => /^\d{10,12}$/.test(v) || "ИНН введен не верно";
    public static isKpp = (v: string) => !v || /^\d{9}$/.test(v) || ValidationRules.abstractText;
    public static isOGRN = (v: string) => /^\d{13}|\d{15}$/.test(v) || "ОГРН введен не верно";
    public static isBankBik = (v: string) => /^\d{9}$/.test(v) || ValidationRules.abstractText;
    public static isIndividualPaymentAccount = (v: string) => (/^\d{20}$/.test(v) && v.startsWith('40817'))  || "Указывайте рублевый счет физ лица, если хотите работать как ИП/ООО напишите в чат поддержки.";
    //40804; 40809; 40812; 40814; 40802 ; или первые три 407
    public static isjurPaymentAccount = (v: string) =>
        (
            /^\d{20}$/.test(v)
            && (
                v.startsWith('40804')
                || v.startsWith('40809')
                || v.startsWith('40812')
                || v.startsWith('40814')
                || v.startsWith('40802')
                || v.startsWith('407')
            )
        ) || "Указывайте рублевый счет ИП или ООО НЕ физ лица. Если у вас его нет, напишите в чат поддержки.";

    public static isCorrespondentAccount = (v: string) => /^\d{20}$/.test(v) || ValidationRules.abstractText;
    public static isYandexWalletNumber = (v: string) => /^\d{12,15}$/.test(v) || ValidationRules.abstractText;
    public static isBankCardNumber = (v: string) => /^\d{16,19}$/.test(v) || ValidationRules.abstractText;

    public static passport = {
        divisionCode: (v: string) => /^\d{6}$/.test(v) || ValidationRules.abstractText,
        series: (v: string) => /^\d{4}$/.test(v) || "Серия введена не верно",
        number: (v: string) => /^\d{6}$/.test(v) || "Номер введена не верно"
    };
}
