<template>
    <v-layout v-if="lastRhythm" column align-center justify-center>
        <h1>История измерений</h1>
        <span
            class="subheading"
            v-text="'Здесь будут отображаться введённые данные за последний анализ'"
        />
        <v-card v-if="!anyItems" class="mt-2 pa-3">
            Измерения отсутствуют
        </v-card>
        <v-layout wrap align-center justify-center>
            <template v-for="(day, di) in lastRhythm.days">
                <v-card flat color="transparent" width="374" v-if="day.items.length > 0" :key="di" class="mt-2" :class="$rs.br.xsOnly ? '' : 'mx-2'">
                    <v-expansion-panel :value="0">
                        <v-expansion-panel-content>
                            <template v-slot:header>
                                <span class="subheading" v-text="`Данные на ${day.items[0].time.format('dd.MM.y')}`" />
                            </template>
                            <span>
                                <template v-for="(item, i) in day.items">
                                    <HistoryItem :item="item" :key="i" />
                                    <v-divider v-if="i+1 != day.items.length" :key="'divider'+i" />
                                </template>
                            </span>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-card>
            </template>
        </v-layout>
    </v-layout>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { RhythmItem } from "_M/rhythmItems";
import HistoryItem from "./Item.vue";

@Component({ components: { HistoryItem } })
export default class History extends Vue {
    created() {
        this.$store.dispatch("patient/getLastRhythm");
    }
    get lastRhythm() {
        return this.$store.state.patient.lastRhythm;
    }
    get anyItems() {
        for (let day in this.lastRhythm.days) {
            if (this.lastRhythm.days[day].items.length > 0) return true;
        }
        return false;
    }
}
</script>