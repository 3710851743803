<template>
    <v-layout class="pa-1" align-start justify-center>
        <template v-for="(day, analCounter) in days">
            <v-flex
                v-if="day.items.length > 0"
                style="width:260px;flex:0 1 auto"
                class="px-2 mx-2 mt-2"
                xs12
                :key="analCounter"
            >
                <div
                    class="subheading font-weight-light text-xs-center mb-1"
                    v-text="`День ${analCounter+1} - ${day.items[0].creationDate.format('dd.MM.y')}`"
                ></div>
                <v-layout style="text-align: center">
                    <v-flex class="pr-2" xs6 style="border-right: 1px solid #eee">
                        <v-layout align-center justify-center>
                            <span v-text="'Выпито'" />
                        </v-layout>
                        <span v-if="day.items.some(x => x.isIntake)">
                            <template v-for="(check, checkCounterIntake) in day.items.filter(x=>x.isIntake)">
                                <v-layout
                                    align-center
                                    justify-space-between
                                    v-if="check.isIntake"
                                    :key="checkCounterIntake"
                                >
                                    <span
                                        class="font-weight-light mr-2"
                                        v-text="check.creationDate.format('HH:mm')"
                                    />
                                    <span style="width: 48px; text-align: right;" v-text="check.value+` мл`" />
                                </v-layout>
                            </template>
                            <v-layout column>
                                <span class="font-weight-bold" v-text="`Всего ${getSum(day, true)} мл`"/>
                            </v-layout>
                        </span>
                        <div v-else>—</div>
                    </v-flex>
                    <v-flex class="pl-2" xs6>
                        <v-layout align-center justify-center>
                            <span v-text="'Выделено'" />
                        </v-layout>
                        <span v-if="day.items.some(x => !x.isIntake)">
                            <template v-for="(check, checkCounter) in day.items.filter(x=>!x.isIntake)">
                                <v-layout
                                    align-center
                                    justify-space-between
                                    v-if="!check.isIntake"
                                    :key="checkCounter"
                                    :style="$rs.br.xsOnly ? 'line-height: 15px;' : ''"
                                >
                                    <span
                                        class="font-weight-light mr-2"
                                        v-text="check.creationDate.format('HH:mm')"
                                    />
                                    <span style="width: 48px; text-align: right;" v-text="check.value+` мл`" />
                                </v-layout>
                            </template>
                            <v-layout column>
                                <span class="font-weight-bold" v-text="`Всего ${getSum(day, false)} мл`"/>
                            </v-layout>
                        </span>
                        <div v-else>—</div>
                    </v-flex>
                </v-layout>
                <v-layout justify-center class="font-weight-bold" v-text="`Количество мочеиспусканий: ${day.items.filter(x=>!x.isIntake).length}`"/>
                <v-divider class="mt-2" />
            </v-flex>
        </template>
    </v-layout>
</template>
<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
@Component
export default class DayData extends Vue {
    @Prop({ default: null }) days;

    getSum(day, isIntake) {
        let items = day.items.filter(x => x.isIntake == isIntake).map(x => x.value);
        return items.reduce((acc, summary) => (acc += summary), 0);
    }
}
</script>