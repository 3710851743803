<template>
    <div
        v-if="isDebug"
        :style="style"
    >
        <span style="color:red" v-text="'debug: '" />
        <span v-text="JSON.stringify(debugData, null, '\t')" />
    </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({ components: {} })
export default class DebugOverlay extends Vue {
    @Prop({default:false}) dark: boolean;

    get style() {
        let style = `
            position: fixed;
            top: 55px;
            left: 185px;
            opacity: 0.5;
            font-size: 9px;
            white-space: pre;
            z-index: 999;
            background: aliceblue;
            `;
        if (this.dark) return `
            z-index: 1;
            position: fixed;
            top:5px;
            left:5px;
            opacity:0.9;
            font-size:9px;
            white-space:pre;
            background:white
        `;
        return style;
    }

    get isDebug() {
        return process.env.NODE_ENV == "development";
    } //process.env.VUE_APP_ENV == "dev"
    get debugData() {
        return this.$store.state.debugData;
    }
}
</script>