<template>
    <v-layout align-center justify-center wrap>
        <v-btn
            @click="$router.push({name: 'input'})"
            :dark="!isEnded"
            large
            color="green"
            :disabled="isEnded"
        >
            <v-icon class="mr-2">mdi-plus-circle</v-icon>
            <span v-text="'Ввести измерение'" />
        </v-btn>
        <v-tooltip top :disabled="isEnded">
            <template v-slot:activator="{ on }">
                <div v-on="on">
                    <v-btn
                        @click="printAnalysis()"
                        :disabled="!isEnded"
                        large
                        color="primary"
                        :loading="isLoading"
                    >
                        <v-icon class="mr-2" v-text="'mdi-printer'" />
                        <span v-text="'Распечатать результат'" />
                    </v-btn>
                </div>
            </template>
            <span v-text="'Печать будет доступна после завершения анализа'" />
        </v-tooltip>
        <v-btn @click="endAnalysis" large :disabled="isEnded || !anyMeasures">
            <v-icon class="mr-2">mdi-check</v-icon>
            <span v-text="'Завершить анализ'" />
        </v-btn>
    </v-layout>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { isYesterday, isToday } from "date-fns";
import Printd from "printd";
import { UserType } from "@/model/common";
import { Rhythm, RhythmStatus } from "@/model/rhythmItems";

@Component({ components: {} })
export default class DashControls extends Vue {
    get lastRhythm(): Rhythm {
        return this.$store.state.patient.lastRhythm;
    }

    get isEnded() {
        if (!this.lastRhythm) return false;
        return this.lastRhythm.status == RhythmStatus.Complete;
    }

    get anyMeasures() {
        if (!this.lastRhythm) return false;
        if (this.lastRhythm.days.length == 0) return false;
        else return true;
    }
    private printer: Printd = new Printd();

    isLoading = false;
    printAnalysis() {
        let link = `${window.location.protocol}//${window.location.host}/#/noapp/analysis/${this.lastRhythm.patientId}?print=true`;
        let printWindow = window.open(link, "_blank");
        printWindow.focus();
    }

    async endAnalysis() {
        let res = await this.$confirm.open({
            title: "Завершить анализа?",
            text:
                "Вы уверены? Завершение анализа запретит добавление и редактирование измерений.",
            color: "error"
        });
        if (res) {
            this.$store.dispatch("patient/complete");
        }
    }
}
</script>