<template>
    <v-card class="py-1 px-3">
        <v-layout align-center>
            <div>{{patient.fullName}}, {{age}}</div>
            <!-- add plurals later -->
            <v-spacer />
            <v-btn outline @click="$router.push('/app/analysis/'+patient.id)">Подробнее</v-btn>
        </v-layout>
    </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import { differenceInCalendarYears } from "date-fns";
import { getPlural } from "@/plugins/utils";

@Component({ components: {} })
export default class PatientCard extends Vue {
    @Prop({ default: null }) patient;
    get age() {
        let age = differenceInCalendarYears(new Date(), this.patient.birthDate);
        return `${age} ${getPlural(age, "год", "лет", "года")}`;
    }
}
</script>