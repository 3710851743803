<template>
    <div id="app">
        <v-app id="inspire">
            <template v-if="currUser">
                <v-speed-dial
                    v-if="isDialVisible"
                    v-model="fab"
                    dark
                    fab
                    fixed
                    bottom
                    right
                    direction="top"
                    open-on-hover
                    transition="slide-y-reverse-transition"
                >
                    <template v-slot:activator>
                        <v-btn
                            v-model="fab"
                            color="blue darken-2"
                            large
                            dark
                            fab
                        >
                            <v-icon v-text="'mdi-plus'" />
                            <v-icon v-text="'mdi-window-close'" />
                        </v-btn>
                    </template>

                    <v-tooltip
                        :open-delay="20"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                fab
                                dark
                                color="green"
                                to="/app/input/in"
                            >
                                <v-icon v-text="'mdi-food-fork-drink'" />
                            </v-btn>
                        </template>
                        <span v-text="'Потреблено'" />
                    </v-tooltip>

                    <v-tooltip
                        :open-delay="20"
                        left
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                fab
                                dark
                                color="green darken-2"
                                to="/app/input/out"
                            >
                                <v-icon v-text="'mdi-toilet'" />
                            </v-btn>
                        </template>
                        <span v-text="'Выделено'" />
                    </v-tooltip>
                </v-speed-dial>
                <v-btn
                    v-if="!isPatient"
                    @click="$router.push('/app/addAnalysis')"
                    absolute
                    large
                    dark
                    fab
                    fixed
                    bottom
                    right
                    color="blue darken-2"
                    class="mb-5"
                >
                    <v-icon v-text="'mdi-plus'" />
                </v-btn>
            </template>
            <Sidebar />
            <Toolbar />
            <v-content>
                <v-container fill-height>
                    <DebugOverlay />
                    <v-slide-y-reverse-transition mode="out-in">
                        <router-view />
                    </v-slide-y-reverse-transition>
                </v-container>
            </v-content>
            <v-footer height="auto">
                <v-layout justify-center column wrap class="text-xs-center mb-2">
                    <!--<span v-text="`ИП «Исследование Партнеры»`" />
                    <span v-text="`ИНН: 9720009030 ОГРН: 1177700004020`" />-->
                    <span v-text="`© 2019 Urythm`" />
                </v-layout>
            </v-footer>
        </v-app>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import {
    Prop as prop,
    Watch as watch,
    Model as model,
    Emit,
    Component
} from "vue-property-decorator";
import Sidebar from "./Sidebar.vue";
import Toolbar from "./Toolbar.vue";
import { UserType } from "@/model/common";
import DebugOverlay from "_C/shared/DebugOverlay.vue";
import Footer from './Footer.vue';

@Component({
    components: { Sidebar, Toolbar, DebugOverlay, "u-footer": Footer }
})
export default class App extends Vue {
    fab = false;

    async created() {
        try {
            await this.$store.dispatch("auth/loadCurrentUser");
            await this.$store.dispatch("patient/getLastRhythm");
        } catch {
            this.$router.push("/noapp/auth");
            console.log("auth/loadCurrentUser error");
        }
    }

    get isPatient() {
        return this.currUser.userType == UserType.Patient;
    }
    get currUser() {
        return this.$store.state.auth.currentUser;
    }
    get lastRhythm() {
        if (this.isPatient) return this.$store.state.patient.lastRhythm;
        else return null;
    }
    get isDialVisible() {
        if (!this.isPatient || !this.lastRhythm) return false;
        return true;
    }
}
</script>