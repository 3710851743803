import { Patient } from '@/model/patient';
import ApiHelper from '@/utils/ApiHelper';
import { httpQuery } from '@/main';

class DoctorState {

    public newPatient: Patient = null;
    public foundPatients: Patient[] = [];
    public patientList: Patient[] = [];
    public rhythmResult = null;
    public payLink=null
}

export const doctorModule = {
    namespaced: true,
    state: new DoctorState(),

    mutations: {
    },

    actions: {
        /** Search for patients by name */
        async searchPatients({state}, patientName: string) {
            let url = new ApiHelper().searchPatients;
            let resp = await httpQuery("get", url, {name: patientName});
            if (!resp) return;
            if (resp.isSuccess) {
                if (resp.data) state.foundPatients = resp.data;
                else state.foundPatients = [];
            }
        },
        /** Add patient for doctor */
        async addPatient({ state, commit }, payload: { patient: Patient, paymentType: number, type:number }) {
            let url = new ApiHelper().createPatient;
            let resp = await httpQuery("post", url, null, null, { ...payload.patient, paymentType: payload.paymentType, type: payload.type });
            
            if (!resp) return false;
            if (resp.isSuccess) return true;
            return false;
        },
        /** Add patient for doctor without sending messages */
        async addPatientWithoutReg({state, commit}, model: Patient) {
            let url = new ApiHelper().createPatientWithoutReg;
            let resp = await httpQuery("post", url, null, null, model);
            if (!resp) return false;
            if (resp.isSuccess) return true;
            return false;
        },
        /** Add analysis for patient */
        async addAnalysis({ state, commit }, payload: { patientId: number, paymentType: number, type: number }) {
            let url = new ApiHelper().addAnalysis;
            let resp = await httpQuery("post", url, null, null, { id: payload.patientId, paymentType: payload.paymentType, type: payload.type });
            
            if (!resp) return false;
            if (resp.isSuccess) return true;
            return false;
        },
        /** Get rhythm result for a patient by his id */
        async getRhythmResult({state, commit}, patientId: number) {
            let url = new ApiHelper().getRhythmResult;
            
            let resp = await httpQuery("get", url, { patientId });
            
            if (!resp) return;
            if (resp.isSuccess) {
                state.rhythmResult = resp.data.rythms;
                state.payLink = resp.data.payLink;
                state.rythmId = resp.data.rythmId;
            }
        },
        async getPatientList({state}, page: number) {
            let url = new ApiHelper().getPatientList;
            let resp = await httpQuery("get", url+"?t="+new Date().getMilliseconds(), {page});
            if (!resp) return;
            if (resp.isSuccess) {
                if (resp.data) state.patientList = resp.data;
                else state.patientList = [];
            }
        }
    }
}