<template>
<span>

    <v-layout class="pa-2">
        <v-layout align-center class="text-xs-center grey lighten-2 ml-2" style="flex: none">
            <DetailsHeader />
        </v-layout>
        <v-layout class="text-xs-left" fill-height column>
            <v-flex
                v-for="(stage, stIndex) in stages" :key="stIndex"
                class="details-flex lighten-4"
                :class="stage.color"
            >
                <v-layout>
                    <span
                        v-for="(item, i) in takeItems(stage.grade, stage.type)"
                        :key="stage.type+stage.grade+i"
                        v-text="item.deviation"
                        class="deviation-cell"
                    />
                    <v-spacer/>
                    <span class="ml-2 mr-1" v-text="takeItems(stage.grade, stage.type).length" />
                </v-layout>
            </v-flex>
        </v-layout>
    </v-layout>
    <span
        class="text-xs-left font-weight-light"
        v-text="`Всего: ${items.length} мочеиспусканий`"
    />
</span>
</template>
<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import DetailsHeader from "./DetailsHeader.vue";
import { getHours } from "date-fns";

@Component({ components: { DetailsHeader } })
export default class DetailsTable extends Vue {
    @Prop({ default: null }) days: {dayNumber: number, items: any[] }[];

    stages = [
        { grade: 4, type: 'hyper', color: 'red' },
        { grade: 3, type: 'hyper', color: 'orange' },
        { grade: 2, type: 'hyper', color: 'yellow' },
        { grade: 1, type: 'hyper', color: 'lime' },
        { grade: 0, type: 'norm', color: 'green' },
        { grade: 1, type: 'hypo', color: 'lime' },
        { grade: 2, type: 'hypo', color: 'yellow' },
        { grade: 3, type: 'hypo', color: 'orange' },
        { grade: 4, type: 'hypo', color: 'red' }
    ]

    get items(): {creationDate: Date, deviation: number, isIntake: boolean, norm: number, stage: number, stageType: string, value: number }[] {
        return this.days.map(x => x.items).flat().filter(x => !x.isIntake);
    }

    takeItems(stage, type: "hyper" | "hypo" | "norm") {
        if (type == 'norm') return this.items.filter(x => x.stageType == "norm");
        let result = this.items.filter(x => x.stage == stage && x.stageType == type);
        return result;
    }

}
</script>
<style lang="scss">
// .details-flex {
//     flex-basis: 11.11111111111111%;
//     flex-grow: 0;
//     max-width: 11.11111111111111%;
// }
.deviation-cell {
    border-right: 1px solid #777;
    font-size: 13px;
    padding-right: 2px;
    margin-right: 1px;
}
</style>