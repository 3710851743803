<template>
    <v-toolbar color="indigo" dense dark fixed app clipped-left>
        <v-toolbar-side-icon @click.stop="$store.commit('toggleSidebar')"></v-toolbar-side-icon>
        <v-toolbar-title @click="$router.push('/app/dash').catch(err => {})" style="font-weight: 700;cursor:pointer">
            <span style="font-weight: 300">Ури</span>тм
        </v-toolbar-title>
        <v-spacer/>
        <!-- <v-btn style="margin-top:60px" light fab>
            <v-icon>mdi-bell</v-icon>
        </v-btn> -->
        <v-menu offset-y bottom open-on-hover>
            <template v-slot:activator="{ on }">
                <span
                    style="height: 100%;display: flex;align-items:center;" v-on="on"
                    v-text="!currUser ? '' : `${currUser.firstName} ${currUser.lastName}`"
                />
            </template>
            <v-list>
                <v-list-tile @click="logout()">
                    <v-list-tile-avatar>
                        <v-icon>mdi-logout-variant</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                        <v-list-tile-title>Выход</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list>
        </v-menu>
    </v-toolbar>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";

@Component
export default class Toolbar extends Vue {
    get currUser() { return this.$store.state.auth.currentUser; }

    logout() {
        this.$store.state.auth.currentUser = {};
        localStorage.removeItem('rhythmtoken');
        this.$router.push("/noapp/auth");
    }
}
</script>