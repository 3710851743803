<template>
    <v-layout class="pa-2" column>
        <v-layout align-center class="text-xs-center grey lighten-2">
            <v-flex
                v-text="'Гиперрефлексия'"
                style="display: flex;justify-content: center;border-right: .25px solid #aaa"
                :style="topHeaderWidth('hyper')"
            />
            <v-flex v-html="'&nbsp;'" :style="topHeaderWidth('norm')" />
            <v-flex
                v-text="'Гипорефлексия'"
                style="display: flex;justify-content: center;border-left: .25px solid #aaa"
                :style="topHeaderWidth('hypo')"
            />
        </v-layout>
        <v-layout align-center class="text-xs-center grey lighten-2" style="overflow: hidden">
            <v-flex
                v-for="(smry, i) in summaryColumns"
                :key="i"
                v-text="smry.title"
                :style="headerWidth(smry.type, smry.stage)"
                :class="smry.color"
            />
        </v-layout>
        <v-layout class="text-xs-center" style="overflow: hidden">
            <v-flex
                v-for="(smry, i) in summaryColumns"
                :key="i"
                v-text="getSummary(smry.type, smry.stage)+'%'"
                :style="headerWidth(smry.type, smry.stage)"
                :class="smry.color"
                class="lighten-4"
            />
        </v-layout>
    </v-layout>
</template>
<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import { RhythmSummary } from "@/model/rhythmItems";

@Component({ components: {} })
export default class SummaryTable extends Vue {
    @Prop({ default: null }) summary: RhythmSummary[];
    summaryColumns = [
        { type: "hyper", stage: 4, title: "Ⅳ", color: "red" },
        { type: "hyper", stage: 3, title: "Ⅲ", color: "orange" },
        { type: "hyper", stage: 2, title: "Ⅱ", color: "yellow" },
        { type: "hyper", stage: 1, title: "Ⅰ", color: "lime" },
        { type: "norm", stage: 0, title: "Норма", color: "green" },
        { type: "hypo", stage: 1, title: "Ⅰ", color: "lime" },
        { type: "hypo", stage: 2, title: "Ⅱ", color: "yellow" },
        { type: "hypo", stage: 3, title: "Ⅲ", color: "orange" },
        { type: "hypo", stage: 4, title: "Ⅳ", color: "red" }
    ];
    headerWidth(stageType, stage) {
        let width = this.getSummary(stageType, stage);
        if (width > 0) return `min-width: ${width}%;`;
        else return "display: none;";
    }

    topHeaderWidth(stageType) {
        if (!this.summary) return "";
        let typeValues = this.summary
            .filter(x => x.stageType == stageType && x.quantity > 0)
            .map(x => x.percent);
        let width = typeValues.reduce((acc, summary) => (acc += summary), 0);
        if (width > 0) return `min-width: ${width}%;`;
        else return "display: none;";
    }

    getSummary(stageType, stage) {
        if (this.summary)
            return this.summary.find(
                x => x.stageType == stageType && x.stage == stage
            ).percent;
        return 0;
    }
}
</script>