<template>
    <div class="calibre" id="calibre_link-0">
        <p class="block_">ПУБЛИЧНАЯ ОФЕРТА</p>
        <p class="block_"> (по договору оказания услуг)</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_2">Индивидуальный предприниматель Полунин Александр Леонидович ИНН 683307012087, ОГРНИП 319682000045689 именуемый в дальнейшем Исполнитель, с одной стороны предлагает Вам, именуемому в дальнейшем «Заказчик», вместе именуемые Стороны, оказание услуг по математической обработки данных диуретического ультразвукового исследования мочевой системы на следующих условиях:</p>
        <p class="block_3">&nbsp;</p>
        <p class="block_4">1.Общие положения</p>
        <p class="block_3">&nbsp;</p>
        <p class="block_2">1.1.Данный документ является официальным предложением - публичной офертой Исполнителя и содержит все существенные условия совершения сделки.</p>
        <p class="block_2">1.2.В соответствии с пунктом 2 статьи 437 Гражданского Кодекса РФ в случае принятия изложенных ниже условий и оплаты счета на услуги юридическое или физическое лицо, производящее акцепт этой оферты (оплата суммы счета, <span class="text_">заполнение заявки</span>) становится Заказчиком (в соответствии с п.3 статьи 438 Гражданского Кодекса РФ акцепт оферты равносилен заключению договора на условиях, изложенных в оферте).</p>
        <p class="block_2">1.3.Акцепт оферты - оплата счета на предоплату или перевод денежных средств<span class="calibre1">  через платежную </span>систему.</p>
        <p class="block_2">1.4.Заказчик соглашается с тем, что до совершения им действий по акцепту, установленных настоящей Офертой, он ознакомился с условиями настоящей Оферты. Заказчик подтверждает, что положения настоящей Оферты ему полностью понятны.</p>
        <p class="block_2">1.5.Акцептирование Заказчиком настоящей оферты означает, что он согласен со всеми ее положениями.</p>
        <p class="block_2">1.6.Договор вступает в силу с момента акцепта оферты и действует до выполнения Сторонами своих обязательств.</p>
        <p class="block_3">&nbsp;</p>
        <p class="block_5">2.Предмет договора</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_2">2.1.Исполнитель обязуется осуществить математическую обработку данных диуретического ультразвукового исследования мочевой системы Заказчика.</p>
        <p class="block_3">&nbsp;</p>
        <p class="block_4">3.Стоимость услуг и порядок оплаты</p>
        <p class="block_6">&nbsp;</p>
        <p class="block_2">3.1.Стоимость услуг Исполнителя составляет 3000 (три тысячи рублей 00 коп.) НДС не облагается (п.2. ст.346.11 НК РФ).</p>
        <p class="block_2">3.2.Оплата по договору производится Заказчиком на условиях 100% предоплаты.</p>
        <p class="block_2">3.3.Расчеты по настоящему договору производятся в российских рублях. Моментом оплаты считается момент зачисления денежных средств на расчетный счет Исполнителя.</p>
        <p class="block_2">3.4.Заказчик оформляет заказ путём заполнения электронного бланка заявки <span class="text_">на сайте Исполнителя</span>. Оформление заказа завершается процедурой подтверждения заказа. При оформлении заказа Заказчик должен корректно заполнить все обязательные реквизиты, в противном случае заказ может быть аннулирован.<br class="calibre2" /> <span class="tab">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>3.5.После подтверждения заказа Исполнитель либо выставляет счет на предоплату, либо обеспечивает возможность перевода денег через электронные платежные системы.</p>
        <p class="block_2">3.6.Заказчик оплачивает услугу посредством перечисления денежных средств на расчетный счет Исполнителя путем оплаты счета банковским переводом с расчетного счета Заказчика, либо путем перевода денежных средств на расчетный счет Исполнителя через электронные платежные системы.</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_5">4.Порядок оказания услуг</p>
        <p class="block_1">&nbsp;</p>
        <p class="block_2">4.1.Заказчик обязуется в течение 3-х(календарных) дней с момента оплаты настоящего договора предоставить сведения необходимые для проведения исследования согласно Приложению №1 (Инструкция по сбору первичных данных) настоящего договора. </p>
        <p class="block_2">4.2.Исполнитель приступает к производству математического расчета в течение 24 (двадцати четырех) часов с момента фактического предоставления сведений, установленных в пункте 4.1 договора.</p>
        <p class="block_2">4.3.Исполнитель осуществляет математическую интерпретацию ритма суточных мочеиспусканий Заказчика в течение 3-х календарных дней с момента, предусмотренного п.4.2 настоящего договора.</p>
        <p class="block_2">4.4.В случае не представления Заказчиком сведений необходимых для проведения исследования в срок, установленный п.4.1 настоящего договора или представления Заказчиком сведений в срок, превышающий установленный пунктом 4.1 настоящего договора Исполнитель имеет право изменить сроки оказания услуг.</p>
        <p class="block_6">&nbsp;</p>
        <p class="block_4">5.Порядок приемки оказанных услуг</p>
        <p class="block_6">&nbsp;</p>
        <p class="block_2">5.1.Акт об оказанных услугах по настоящему договору отдельно не составляется.</p>
        <p class="block_2">5.2.Исполнитель направляет Заказчику результат оказания услуги в электронном виде в формате  файла Exel</p>
        <p class="block_2">5.3.Заказчик имеет право в течение 3-х календарных дней после оказания услуги направить письменный мотивированный отказ от ее приемки, переданный посредством электронной почты<span class="calibre1"> или другим способом позволяющим, надлежащим образом подтвердить его  получение Исполнителем</span>.</p>
        <p class="block_3">&nbsp;</p>
        <p class="block_4">6.Заключительные положения</p>
        <p class="block_6">&nbsp;</p>
        <p class="block_2">6.1.Заказчик несет ответственность за достоверность представленных данных. В случае несоответствия действий Заказчика требованиям, отраженным в Приложении №1 настоящего договора ложатся риски некорректного расчета.</p>
        <p class="block_2">6.2<span class="calibre1">.Контактными  e-</span>mail адресами со стороны Заказчика считаются <span lang="en">e</span>-<span lang="en">mail</span> адреса указанные Заказчиком в заявке. Контактными e-mail адресами со стороны Исполнителя считаются:uzimatteh@gmail.com. </p>
        <p class="block_2">Письма, отправленные с данных e-mail адресов, считаются отправленными уполномоченными представителями сторон и могут служить доказательствами в судебном разбирательстве.</p>
        <p class="block_2">6.3.В случае изменения реквизитов, адресов и способов уведомления, Сторона, у которой такие изменения произошли, обязана в течение трех рабочих дней сообщить о таких изменениях.</p>
        <p class="block_2">6.4.Уведомления и сообщения по электронной почте будут считаться переданными надлежащим образом, в случае получения подтверждения о получении электронного сообщения.</p>
        <p class="block_2">6.5.Во всем, что не предусмотрено положениями настоящей Оферты, Стороны будут руководствоваться действующим законодательством Российской Федерации не ограничиваясь<span class="calibre1">,  законодательством о защите прав потребителей.</span></p>
        <p class="block_2">6.6.Споры и разногласия, которые могут возникнуть в связи с исполнением/неисполнением, положений настоящей Оферты и по предмету настоящего договора, Стороны будут разрешать путем переговоров. </p>
        <p class="block_2">Соблюдение досудебного претензионного порядка урегулирования (разрешения) споров является для Сторон обязательным.</p>
        <p class="block_2"> Срок рассмотрения претензии &ndash; 15 (пятнадцать) рабочих дней с момента получения ее оригинала.</p>
        <p class="block_2">В случае если Стороны не достигнут согласия, спор передается на рассмотрение в суд по месту нахождения Исполнителя, указанному в разделе 7 настоящего договора.</p>
        <p class="block_2">6.7.Исполнитель вправе в любое время вносить изменения в настоящую Оферту, но в любом случае такие изменения публикуются и доводятся до всеобщего сведения посредством опубликования на сайте Исполнителя. Использование Закзачиком услуг Исполнителя после внесения изменений в текст настоящей Оферты означает акцепт Оферты с учетом внесенных изменений.</p>
        <p class="block_2">6.8. В соответствии с положениями Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных» Заказчик<span class="calibre1"> признает и соглашается с  тем, что совершение </span>Заказчиком действий, указанных в пункте 1<span class="calibre1">.3. Оферты, является полным и информированным согласием  </span>Заказчика на предоставление Исполнителю своих персональных данных, а именно: фамилия, имя, отчество, контактный номер телефона, адрес, дату рождения (или возраст) и контактный адрес электронной почты.</p>
        <p class="block_2">6.9.Настоящим Заказчик признает и соглашается с тем, что предоставление Исполнителю персональных данных, указанных в пункте 6.9 Оферты, осуществляется в целях оказания Заказчику услуг в соответствии с пунктом 2.1. Оферты. Заказчик дает Исполнителю свое согласие на обработку персональных данных, предоставляемых при оформлении заказа, для оказания Заказчику, предусмотренных настоящей Офертой. Срок использования предоставленных персональных данных - бессрочно. </p>
        <p class="block_2">6.10.<span class="calibre1">Обработка персональных данных осуществляется в соответствии с Федеральным законом от 27 июля 2006 года № 152-ФЗ «О персональных данных» и включает в себя сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), сортировку, использование, обезличивание, блокирование, уничтожение.  </span></p>
        <p class="block_3">&nbsp;</p>
        <p class="block_4">7.Реквизиты сторон</p>
        <p class="block_7">&nbsp;</p>

        <p class="block_8">ИП «Полунин Александ Леонидович»</p>
        <p class="block_8">ИНН: 683307012087</p>
        <p class="block_8">ОГРНИП: 319682000045689</p>
        <!--<table class="table_">
            <tbody class="calibre3">
                <tr class="calibre4">
                    <td class="td_"><p class="block_8">Исполнитель</p></td>
                    <td class="td_1"><p class="block_9">Заказчик</p></td>
                </tr>
                <tr class="calibre4">
                    <td class="td_"><p class="block_10">ИП «Полунин Александ Леонидович»</p></td>
                    <td class="td_2"><p class="block_11">&nbsp;</p></td>
                </tr>
                <tr class="calibre4">
                    <td class="td_3"><p class="block_12">ИНН: 683307012087</p></td>
                    <td class="td_4"><p class="block_13">&nbsp;</p></td>
                </tr>
                <tr class="calibre4">
                    <td class="td_3"><p class="block_14">ОГРНИП: 319682000045689</p></td>
                    <td class="td_4"><p class="block_13">&nbsp;</p></td>
                </tr>
            </tbody>
        </table>-->
        <p class="block_3">&nbsp;</p>
        <p class="block_3">&nbsp;</p>
        <p class="block_3">&nbsp;</p>

    </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { UserType } from "@/model/common";

@Component
export default class PublicOfferDuzi extends Vue {}
</script>

<style lang="scss">.block_,
.block_1,
.block_2,
.block_3,
.block_4,
.block_5,
.block_6,
.block_7,
.block_10,
.block_11,
.block_12,
.block_13,
.block_14,
.calibre {
    color: #000;
    display: block;
    line-height: 1.3;
}

.block_ {
    font: 700 1.125em/1.2 "Times New Roman", serif;
    margin: 0;
    padding: 0;
    text-align: center
}

.block_1 {
    font: .75em serif;
    margin: 0;
    padding: 0;
    text-align: center
}

.block_2,
.block_3 {
    margin: 0;
    text-align: justify
}

.block_2 {
    padding: 0;
    text-indent: 35.4pt
}

.block_3 {
    font: .75em serif
}

.block_3,
.block_4 {
    padding: 0;
    text-indent: 35.4pt
}

.block_4,
.block_5 {
    margin: 0;
    text-align: center
}

.block_5,
.block_6,
.block_7 {
    padding: 0
}

.block_6 {
    font: .75em serif;
    margin: 0;
    text-align: center;
    text-indent: 35.4pt
}

.block_7 {
    font: .75em/1.15 serif;
    margin: 0 0 0 18pt
}

.block_8,
.block_9 {
    display: block;
    font-weight: 700;
    margin: 0;
    padding: 0
}

.block_9 {
    text-align: justify
}

.block_10,
.block_11 {
    font: .75em serif;
    padding: 0
}

.block_10 {
    background-color: #fff;
    margin: 0;
    text-align: center
}

.block_11 {
    margin: 0 0 0 5pt;
    text-align: justify
}

.block_12,
.block_13 {
    font: .75em serif;
    margin: 6pt 0 0;
    padding: 0
}

.block_13 {
    text-align: justify
}

.block_14 {
    font: .75em serif;
    margin: 6pt 0 0;
    padding: 0;
    text-align: center
}

.calibre {
    font: 1em "Times New Roman", serif;
    margin: 0 5pt;
    padding-left: 0;
    padding-right: 0
}

.calibre1 {
    white-space: pre-wrap
}

.calibre2 {
    display: block
}

.calibre3 {
    display: table-row-group;
    vertical-align: middle
}

.calibre4 {
    display: table-row;
    vertical-align: inherit
}

.tab {
    white-space: pre
}

.table_,
.td_,
.td_1,
.td_2,
.td_3,
.td_4 {
    border-color: #000;
    border-style: solid;
    border-width: 1pt;
    padding: 0
}

.table_ {
    border-collapse: collapse;
    border-spacing: 2px;
    display: table;
    margin-bottom: 0;
    margin-left: -18.4pt;
    margin-top: 0;
    text-indent: 0;
    width: 507pt
}

.td_,
.td_1,
.td_2,
.td_3,
.td_4 {
    background-color: #fff;
    display: table-cell;
    text-align: inherit;
    vertical-align: middle;
    width: 254pt
}

.td_1,
.td_2,
.td_3,
.td_4 {
    width: 253pt
}

.td_2,
.td_3,
.td_4 {
    vertical-align: bottom
}

.td_3,
.td_4 {
    vertical-align: top;
    width: 254pt
}

.td_4 {
    width: 253pt
}

.text_ {
    background-color: silver
}
</style>