export interface RhythmItem {
    isIntake: boolean;
    captureDate: Date;
    volume: number;
}

export interface RhythmDay {
    items: RhythmItem[];
    date: Date;
}

export class Rhythm {
    id: number;
    patientId: number;
    days: RhythmDay[];
    beginDate: Date = null;
    status: RhythmStatus;
}

export interface RhythmSummary {
    percent: number;
    quantity: number;
    stage: number;
    stageType: "hyper" | "hypo" | "norm";
}

export enum RhythmStatus {
    New = 1,
    InProgress,
    Complete
}