<template><div></div></template>
<script lang="ts">
import {Vue, Component, Watch} from "vue-property-decorator";
import { MessageType } from '@/model/shared/message';
// import LocalStorage from 'local-storage'
//https://github.com/bevacqua/local-storage

@Component
/**
 * Этот компонент нужен для пробрасывания
 * через переменные прототипа Vue реактивные свойства
 * Как использовать: В любом компоненте можно
 * обращаться к свойствам, например this.$rs.%prop%
 */
export default class ReactiveState extends Vue {
    mounted() {Vue.prototype.$rs = this;}
    get br() {return this.$vuetify.breakpoint}
    get msgType() {return MessageType}
    get debug() {return this.$store.state.debugData;}
    set debug(val) {this.$store.state.debugData = val;}
    // get ls(): any {return LocalStorage}
    /** how to use:
     *  this.$rs.%prop%
     */
}
</script>