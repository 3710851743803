<template>
    <v-layout class="mb-3" column align-center justify-center>
        <span
            class="white--text title mb-3"
            style="font-weight: 300"
            v-text="'Добро пожаловать в Уритм'"
        />
        <v-card class="pa-3" width="300" @keyup.enter="submit">
            <v-form ref="loginForm">
                <v-text-field box label="E-mail"
                    v-model="creds.login"
                    :rules="[Rules.requiredRule, Rules.isEmail]"
                />
                <v-text-field box label="Пароль" type="password" hide-details class="mb-2"
                    v-model="creds.pass"
                    :rules="[Rules.requiredRule]"
                />
                <v-expand-transition>
                    <div v-if="isError" style="color:red;text-align:center">
                        Введён неверный логин или пароль.<br>Попробуйте ещё раз.
                    </div>
                </v-expand-transition>
                <v-btn color="success" block @click="submit" :loading="loading">Войти</v-btn>
                <router-link to="/noapp/auth/register">Зарегистрироваться</router-link>
            </v-form>
        </v-card>
    </v-layout>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";
import { ValidationRules } from '@/utils/validate';

@Component
export default class Login extends Vue {
    Rules = ValidationRules;

    creds = {
        login:"",
        pass: ""
    }
    isError = false;
    loading = false;

    async submit() {
        if (!(this.$refs.loginForm as any).validate()) return;
        this.loading = true;
        let isSuccess = false;
        try {
            isSuccess = await this.$store.dispatch("auth/authorize", this.creds);
        }
        catch {
            this.isError = true;
            this.loading = false;
        }
        if (isSuccess) this.$router.push("/app/dash");
        else this.isError = true;
        this.loading = false;
    }
}
</script>