<template>
    <span v-if="item">
        <!-- view -->
        <v-scroll-y-transition leave-absolute>
            <v-layout style="height: 52px; font-size: 20px;" class="px-2" justify-center align-center v-if="!isEdit">
                <v-icon class="mr-1">mdi-calendar</v-icon>
                <span class="mr-3" v-text="`${item.time.format('HH:mm')}`" />
                <v-icon
                    class="mr-1"
                    :color="item.isIntake ? 'light-blue darken-2' : 'amber darken-2'"
                    v-text="item.isIntake ? 'mdi-food-fork-drink' : 'mdi-toilet'"
                />
                <span style="width: 80px" v-text="`${item.value} мл`" />
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="isEnded" @click="edit()" color="primary" icon v-on="on">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Редактировать</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn :disabled="isEnded" @click="remove(item.id)" color="error" icon v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Удалить</span>
                </v-tooltip>
            </v-layout>
        </v-scroll-y-transition>
        <!-- edit -->
        <v-scroll-y-reverse-transition hide-on-leave>
            <v-layout class="px-2" align-center v-if="isEdit">
                <v-icon class="mr-1">mdi-calendar</v-icon>
                <span style="font-size: 20px;" v-text="`${editedEntity.time.format('HH:mm')}`" />
                <v-btn
                    icon
                    outline
                    @click="editedEntity.isIntake = !editedEntity.isIntake"
                    class="ml-3 mr-2"
                    :color="editedEntity.isIntake ? 'light-blue darken-2' : 'amber darken-2'"
                >
                    <v-icon v-text="editedEntity.isIntake ? 'mdi-food-fork-drink' : 'mdi-toilet'" />
                </v-btn>
                <v-text-field
                    @keydown.down="editedEntity.value--"
                    @keydown.up="editedEntity.value++"
                    v-model="editedEntity.value"
                    hide-details
                    solo
                    suffix="мл"
                    mask="###"
                    @input="val => processVolumeInput(val)"
                    class="volume-input"
                />
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="save()" color="green" dark icon v-on="on" :loading="isItemSaving">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </template>
                    <span>Сохранить</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="cancel()" color="red" dark icon v-on="on">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Отменить изменения</span>
                </v-tooltip>
            </v-layout>
        </v-scroll-y-reverse-transition>
    </span>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { isYesterday, isToday } from "date-fns";
import { Rhythm, RhythmStatus } from "@/model/rhythmItems";
@Component({ components: {} })
export default class HistoryItem extends Vue {
    @Prop({ default: null }) item;

    isItemSaving = false;

    get lastRhythm(): Rhythm {
        return this.$store.state.patient.lastRhythm;
    }

    get isEnded() {
        if (!this.lastRhythm) return false;
        return this.lastRhythm.status == RhythmStatus.Complete;
    }

    isEdit = false;

    processVolumeInput(val) {
        let number = Number.parseInt(val);
        if (isNaN(number)) number = 0;
        this.editedEntity.value = number;
    }

    editedEntity = { isIntake: false, value: 0, time: null };

    edit() {
        Object.assign(this.editedEntity, this.item);
        this.isEdit = true;
    }

    async save() {
        Object.assign(this.item, this.editedEntity);
        this.isItemSaving = true;
        await this.$store.dispatch("patient/updateMeasure", this.item);
        this.isItemSaving = false;
        this.isEdit = false;
        
    }

    cancel() {
        // revert it
        this.isEdit = false;
    }
    async remove(id) {
        let res = await this.$confirm.open({
            title: "Удаление измерения",
            text: "Вы уверены? Это действие безвозвратно удалит измерение.",
            color: "error"
        })
        if (res) {
            this.$store.dispatch("patient/removeMeasure", id);
        }
    }
}
</script>