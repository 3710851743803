import { RhythmDay, Rhythm, RhythmItem } from '@/model/rhythmItems';
import { Module } from 'vuex';
import { CommonState } from '../store';
import { httpQuery } from '@/main';
import ApiHelper from '@/utils/ApiHelper';
import { StageValue } from '@/model/dashboardModels';

class DashboardState {
    stageValues: StageValue[] = []
}

export const dashboardModule: Module<DashboardState, CommonState> = {
    namespaced: true,
    state: new DashboardState(),
    mutations: {
        setData(state: DashboardState, data) {
            state.stageValues = data;
        }
    },
    actions: {
        async loadData({ commit }) {
            let api = new ApiHelper();
            let url = api.combineUrl("/api/Rhythm/results/1");
            let res = await httpQuery("get", url);
            commit("setData", res);
        }
    }
}