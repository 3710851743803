<template>
    <div>
        <message ref="message"/>
        <confirm :key="$route.path == '/'" ref="confirm"/>
        <reactivestate/>
        <router-view/>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Watch, Component } from "vue-property-decorator";

@Component
export default class Main extends Vue {
    created() {
        this.$store.dispatch("auth/checkAuth");
    }

}
</script>
