import AnalysisList from "_C/Analysis/AnalysisList.vue"
import InputContainer from '_C/Patient/Input/InputContainer.vue';
import AddAnalysis from '_C/Analysis/Create/AddAnalysis.vue';
import NoApp from '_C/Home/NoApp.vue';
import NotFound from '_C/Home/NotFound.vue';
import App from '_C/Home/App.vue';
import Login from '_C/Auth/Login.vue';
import PatientFullCard from '_C/Analysis/PatientFullCard.vue';
import Register from '_C/Auth/Register.vue';
import ResetPass from '_C/Auth/ResetPass.vue';
import { UserType } from './model/common';
import HelpScreen from './components/Help/Screen.vue';
import DashboardScreen from './components/Dashboard/Screen.vue';
import { RouteConfig } from 'vue-router';
import Auth from './components/Auth/Auth.vue';
import Payment from './components/Payment/Payment.vue';

const routes: RhythmRoute[] = [
    {
        path: '/app',
        title: "Главная",
        component: App,
        meta: { isVisible: true, rootPath: true },
        children: [
            {
                path: "dash",
                alias: "",
                title: "Главный экран",
                component: DashboardScreen,
                meta: { isVisible: true, allowedUserTypes: [UserType.Patient, UserType.Doctor] }
            },
            {
                path: "input/:type",
                title: "Ввести измерение",
                name: "input",
                component: InputContainer,
                meta: { isVisible: false, allowedUserTypes: [UserType.Patient] },
                props: (route) => ({ qtype: route.params.type })
            },
            {
                path: "analyzes",
                name: "analyzes",
                title: "Исследования",
                component: AnalysisList,
                meta: { isVisible: true, allowedUserTypes: [UserType.Doctor] }
            },
            {
                path: "analysis/:id",
                title: "Подробности",
                component: PatientFullCard,
                meta: { isVisible: false, allowedUserTypes: [UserType.Doctor] },
                props: (route) => ({ id: route.params.id })
            },
            {
                path: "addAnalysis",
                title: "Создание исследования",
                component: AddAnalysis,
                meta: { isVisible: true, allowedUserTypes: [UserType.Doctor] }
            },
            {
                path: "help",
                title: "Помощь",
                component: HelpScreen,
                meta: { isVisible: true, allowedUserTypes: [UserType.Patient] }
            }
        ]
    },
    {
        path: "/noapp",
        title: "noapp",
        component: NoApp,
        meta: { isVisible: false, allowAnonymous: true },
        children: [
            {
                path: "auth",
                title: "Логин",
                component: Auth,
                meta: { allowAnonymous: true },
                children: [
                    {
                        path: "",
                        title: "Войти",
                        component: Login,
                        meta: { allowAnonymous: true }
                    },
                    {
                        path: "register",
                        title: "Регистрация",
                        component: Register,
                        meta: { allowAnonymous: true }
                    }

                ]
            },
            {
                path: "resetPassword",
                title: "Задать пароль",
                component: ResetPass,
                meta: { allowAnonymous: true },
                props: (route) => ({ userId: route.query.userId, code: route.query.code })
            },
            {
                path: "analysis/:id",
                title: "Подробности",
                component: PatientFullCard,
                meta: { allowAnonymous: false },
                props: (route) => ({ id: route.params.id, print: route.query.print })
            },
            {
                path: "payment/:id",
                title: "Оплата",
                component: Payment,
                meta: { allowAnonymous: true },
                props: (route) => ({ id: route.params.id })
            }
        ]
    },
    { path: '/404', alias: '*', component: NotFound }
]

export default routes;

export interface RhythmRoute extends RouteConfig {
    meta?: RhythmRouteMeta
    children?: RhythmRoute[],
    title?: string
}

/**
 * @param rootPath children of rootPath will be used for sidebar navigation, should be only one
 * @param isVisible indicated if an entry should be visible in sidebar navs
 * @param allowAnonymous allows unauthorized users for a route
 * @param allowedUserTypes shows route only for selected user types
 */
export class RhythmRouteMeta {
    allowAnonymous?: boolean
    isVisible?: boolean
    allowedUserTypes?: UserType[]
    rootPath?: boolean
}